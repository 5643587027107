import { observer } from "mobx-react-lite";
import { Box, Heading, Text, Center, VStack, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect } from "react";
import { projectCreateController } from "../store/ProjectCreateController";

interface BranchStepProp{
  branches: string[]
}

export const StepSelectBranch = observer((
  prop: BranchStepProp
) => {

  const projectData = projectCreateController;
  const branches = prop.branches;

  useEffect(() => {
    if (branches.length === 1) {
      projectData.setBranch(branches[0]);
    }
  }, [branches]);
  
  const handleRowClick = (branchName: string) => {
    projectData.setBranch(branchName);
  };

  return(
    <>
      <Box bg={"white"}>
        <Center>
        <VStack>
          <Heading size={'md'}>Select Branch</Heading>
          
          {projectData.branch && 
            <Text size={'sm'}>({projectData.branch})</Text>
          }
        
        </VStack>
        </Center>
        <Box h={5} />
        <Center>
          <Box maxHeight="200px" overflowY="auto">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Branch Name</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  branches.map((branchName) => {
                    return (
                      <Tr
                        key={branchName}
                        onClick={() => handleRowClick(branchName)}
                        cursor="pointer"
                        bg={projectData.branch === branchName ? "blue.100" : ""}
                      >
                        <Td>{branchName}</Td>
                      </Tr>
                    );
                  })
                }
              </Tbody>
            </Table>
          </Box>
        </Center>
      </Box>
      
    </>
  )

});