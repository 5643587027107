import { observer } from "mobx-react-lite";
import { Box, Heading, Button, Text, Container, Stack, Center, useColorModeValue, VStack, Flex, Spacer } from "@chakra-ui/react";
import { Steps, Step } from "chakra-ui-steps";
import { useSteps } from "chakra-ui-steps";
import { Repository } from "../../lib/utils/backend";
import { useState } from "react";
import { repositoryController } from "../store/RepositoryController";
import { StepSelectRepository } from "./stepSelectRepository";
import { StepSelectBranch } from "./stepSelectBranch";
import { useNavigate } from "react-router-dom";
import { projectCreateController } from "../store/ProjectCreateController";
import { projectController } from "../store/ProjectController";

export const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <Box
      sx={{
        p: 2,
        rounded: "md",
        boxShadow: "sm",
        bg: useColorModeValue("gray.50", "gray.800"),
      }}
      m='10px'
    >
      <Text fontSize="md" color="red.400" fontWeight={"bold"}>
        {message}
      </Text>
    </Box>
  );
};


export const CreateProject = observer(() => {
  const navigate = useNavigate();
  
  const projController = projectController;
  const projectData = projectCreateController;
  const repoController = repositoryController;

  const [branches, setBranches] = useState<string[]>([]);
  const [loaded, setLoaded] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onRepositorySelect = (repository: Repository) => {
    setErrorMessage("");

    projectData.setRepository(repository);
    
    setLoaded(false);
    repoController.getBranchesFromRepository(repository.repo_url).then((branches) => {
      setBranches(branches);
      setLoaded(true);
    });
  };

  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const onSubmit = () => {
    switch (activeStep) {
      case 0:
        console.log("Repository");

        if(projectData.repo === null){
          console.error("Repository is null");
          setErrorMessage("Please select a repository");
          return;
        }
        setErrorMessage("");
        nextStep();
        break;
      case 1:
        console.log("Branch");
        if(projectData.branch === ""){
          console.error("Branch is null");
          setErrorMessage("Please select a branch");
          return;
        }
        setErrorMessage("");
        nextStep();
        break;
      case 2:
        console.log("Confirm");  
        if(projectData.repo !== null && projectData.branch !== ""){
          console.log("Repository: " + projectData.repo.repo_name + " Branch: " + projectData.branch);
          setErrorMessage("");
          projController.createProject(projectData.repo.repo_url, projectData.branch);
          projectData.reset();
          navigate("/dashboard/projects");
        }
        else{
          setErrorMessage("Please select a repository and branch");
        }
        break;
    }
  };

  return (
    <>
      <Box as="section" bg="bg.surface">
        <Container py={{ base: '16', md: '12' }}>
          <Stack spacing={{ base: '8', md: '5' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'lg' }}>Build your workflow</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
              This information will let us know more about your repository.
              </Text>
              
            </Stack>
          </Stack>
        </Container>
      </Box>
      
      <Center>
      {
        errorMessage && <ErrorMessage message={errorMessage} />
      }
      </Center>
      <Center>
        <Box width={"90%"} bg="white" p={5} height={'90%'}>
            <Steps activeStep={activeStep} variant="circles-alt" p={10} >
              <Step label="Repository" description="This is the first step">
                <Center><StepSelectRepository onRepositorySelect={onRepositorySelect} /></Center>
              </Step>
              <Step label="Branch" description="This is the second step">
                <Center><StepSelectBranch branches={branches}/></Center>
              </Step>
              <Step label="Confirm" description="This is the third step">
                <Center>
                  <VStack>
                  <Text>Repository: {projectData.repo?.repo_name}</Text>
                  <Text>Branch: {projectData.branch}</Text>
                  </VStack>
                </Center>
              </Step>
            </Steps>

            <Flex>
              <Button m={3} variant={"ghost"} onClick={()=>{projectData.reset()}}>
                  Reset
              </Button>
              <Spacer />
              <Center>
                <Button m={3} variant="ghost" isDisabled={activeStep === 0} onClick={prevStep}>
                  Prev
                </Button>
                
                <Button onClick={onSubmit} variant={'solid'}>
                  {activeStep === 2 ? "Finish" : "Next"}
                </Button>
              </Center>
            </Flex>
            
        </Box>
       
      </Center>
    </>
  );
});
