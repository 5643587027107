import { observer } from "mobx-react-lite";
import { Box, Text, HStack, Spinner, Spacer, useDisclosure, Button, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, IconButton, Menu, MenuButton, MenuItem, MenuList, keyframes, Tooltip, VStack } from "@chakra-ui/react";
import { Block, deleteProjectSequence, getSeqeunceBlocks, getSequence, getSequenceTargetFiles, patchBlock, startSequence, stopSequence } from "../../../../lib/utils/backend";
import React, { useEffect } from "react";

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { BsThreeDots } from "react-icons/bs";
import { TbPlayerPlayFilled, TbPlayerPauseFilled } from "react-icons/tb";

import { CiSquareRemove } from "react-icons/ci";
import { AddBlock } from "./AddBlock";
import { BlockItem } from "./Block";


const laserAnimation = keyframes`
  0%{
    opacity: 00;
    width: 0%;
    height: 0%;
    left: 10px;
    top: 60%;
    width: 0%;
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 0 2px #ff00f7;
    filter: blur(5px);
    border: 0px solid #FFF;
    height: 0%;
    left: 20px;
    top: 60%;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 0 0 #2200ff;
    transform: scaleX(1);
    height: 0%;
    left: 10px;
    width: 100% - 20px;
    top: 60%;
  }
`;


export const SequenceGroup = observer((
  { sequenceId, name, onDeleted }: 
  { sequenceId:string, name:string,
    onDeleted: () => void,
   }) => {
  const [blocks, setBlocks] = React.useState<Block[]>([]);
  const { isOpen:isDeleteOpen, onOpen:onDeleteOpen, onClose:onDeleteClose } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const [onMenu, setOnMenu] = React.useState(false);
  const [is_listening, setIsListening] = React.useState(false);
  const [is_running, setIsRunning] = React.useState(false);
  const [terminal, setTerminal] = React.useState<Block | null>();
  const [blockRunningStatus, setBlockRunningStatus] = React.useState<boolean[]>([]);

  // get seqeunce files
  const [seqeunceFiles, setSeqeunceFiles] = React.useState<string[]>([]);


  const loadBlocks = () => {
    getSeqeunceBlocks(sequenceId).then((res) => {
      
      console.log("load blocks:");
      // collect only terminal blocks
      let terminal_temp: Block | null = null;

      res = res.filter((block) => {
        if(block.config.is_termination){
          console.log('Terminal block:', block);
          terminal_temp = block;
          terminal_temp.index = 999;
          return false;
        }
        return true;
      });

      if(terminal_temp !== null){
        let t : Block = terminal_temp;
        patchBlock(t.id, {index: 999, config_id: t.config.id}).then((res) => {
          console.log("PATCH terminal index: " + res.index);
        });
      }

      setTerminal(terminal_temp);
      
      console.log(res);
      console.log(terminal_temp);
      setBlocks(res)
    });
    runningStateUpdate();
    setBlockRunningStatus(blocks.map(() => false));
  }

  const runningStateUpdate = () => {
    getSequence(sequenceId).then((res) => {
      setIsListening(res.is_listening);
      setIsRunning(res.is_running);
    });

    getSeqeunceBlocks(sequenceId).then((res) => {
      const block_running_status = res.map((block) => {
        return block.is_running;
      });
      setBlockRunningStatus(block_running_status);
    });
  }

  React.useEffect(() => {
    loadBlocks();
    getSequenceTargetFiles(sequenceId).then((res) => {
      setSeqeunceFiles(res.path_list);
    });
  }, [sequenceId]);

  const deleteSequence = () => {
    deleteProjectSequence(sequenceId).then((res) => {
      console.log(res);
      onDeleted();
    });
  }

  const onToggleListening = async () => {
    // patch sequence
    try{
      if(is_listening){
        setIsListening(false);
        setBlockRunningStatus(blockRunningStatus.map(() => false));
        await stopSequence(sequenceId);
      }
      else{
        setIsListening(true);
        await startSequence(sequenceId);
        runningStateUpdate();
      }
      
    }catch(e){
      console.error(e);
    }
  }

  useEffect(() => {
    if(is_listening){
      // polling server status
      // for every 1sec
      const interval = setInterval(() => {
        runningStateUpdate();
      }, 1000);
      
      return () => clearInterval(interval);
    }
  }, [is_listening]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newBlocks = Array.from(blocks);
    const [reorderedBlock] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, reorderedBlock);
    console.log('onDragEnd');
    newBlocks.forEach((block, index) => {
      const data = {
        index: index,
        config_id: block.config.id
      }
      patchBlock(block.id, data).then((res) => {
        console.log("PATCH index: " + res.index);
      });
    });

    console.log(newBlocks);
    setBlocks(newBlocks);
  };


  return (
    <Box p='5px' mb='10px'
      border={'1px'} borderRadius={'10px'}
      borderStyle={'solid'}
      borderColor={'gray.300'}
      boxShadow={'md'}
      onMouseEnter={() => setOnMenu(true)}
      onMouseLeave={() => setOnMenu(false)}
      position={'relative'}
      /*
      _after={is_listening?{
        content: '""',
        width: "70%",
        height: "3px",
        background: "#DDD",
        borderRadius: "10px",
        position: "absolute",
        bottom: 0,
        left: 0,
        boxSizing: "border-box",
        animation: `${hitZak} 2.0s ease-in-out infinite alternate`,
      }:{}}
      */
     
      _after={is_listening ? {
        content: '""',
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        border: "1px solid rgb(35, 77, 48)",
        position: "absolute",
        borderRadius: "10px",
        left: 0,
        top: 0,
        zIndex: 2,
        animation: `${laserAnimation} 1.8s ease-in-out infinite`,
        //animationDelay: "1s",
      } : {}}

      /*
      sx={ is_listening ? {
        animation: 'blink 1s ease-in-out infinite',
        '@keyframes blink': {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.7 },
        },
      }:{}}
      */
      >
      <HStack>
        {
          is_listening && is_running && (
            <Spinner size="sm" color="green.500" />
          )
        }
        <Box onClick={onToggleListening} style={{ cursor:"pointer"}}>
          {is_listening ? (
            <Box
              as={TbPlayerPauseFilled}
              size={30}
              color="green"
            />
          ) : (
            <TbPlayerPlayFilled size={30} color="green" />
          )}
        </Box>
        <Tooltip label={
          <VStack alignItems={'left'}>
            {
            seqeunceFiles.map((file, index) => {
              return <Text key={index}>{file}</Text>
            })
            }
            <Text>
              And more...
            </Text>
          </VStack>
        } placement="top">
          <Text fontSize={'large'} fontWeight={'800'} pl='10px'>{name}</Text>
        </Tooltip>
        
        <Spacer/>

        <Box hidden={!onMenu || is_listening}  zIndex={999}>
          <Menu closeOnSelect={false}  >
            <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<BsThreeDots size={20} />}
                variant='outline'
                h={'25px'}
                border={0}
              />
              <MenuList>
                
                <MenuItem icon={<CiSquareRemove color="red"/>} onClick={onDeleteOpen}>
                  Delete this sequence
                </MenuItem>
              </MenuList>
          </Menu>
        </Box>
      </HStack>
      
      
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" 
        direction="horizontal"
        >
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} h={'80px'}>
              <HStack align={'left'}>
                <Box overflowX={'auto'} zIndex={900}>
                  <HStack>
                  {blocks.map((block, index) => (
                    <BlockItem key={block.id} is_drag_disable={is_listening} isRunning={blockRunningStatus[index]}  index={index} block={block} onDeleted={loadBlocks} />
                  ))}
                  {provided.placeholder}
                  
                  </HStack>
                </Box>
                <Box 
                    //hidden={!onMenu}
                    hidden={is_listening}
                    zIndex={900}
                    >
                      <AddBlock onAdded={loadBlocks} is_terminal={false} sequenceId={sequenceId}/>
                </Box>
                <Spacer/>
                {terminal && (
                  <Box zIndex={900}>
                  <BlockItem key={terminal.id} is_drag_disable={is_listening} isRunning={blockRunningStatus[blocks.length]} index={99} block={terminal} onDeleted={loadBlocks} />
                  </Box>
                )}
                {!terminal && (
                <Box 
                  //</HStack>hidden={!onMenu}
                  zIndex={900}
                  hidden={is_listening}
                  >
                    <AddBlock onAdded={loadBlocks} is_terminal={true} sequenceId={sequenceId}/>
                </Box>
                )}

              </HStack>
            </Box>
          )}
        </Droppable>
      </DragDropContext>


      <AlertDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete this workflow
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onDeleteClose} ref={cancelRef}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={deleteSequence} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
});