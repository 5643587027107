
import { Box } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, Link as RouterLink } from "react-router-dom";
import SidebarWithHeader from "../sidebarWithHeader";
import { observer } from "mobx-react-lite";
import { ProjectList } from "../menu/ProjectList";
import { projectController } from "../store/ProjectController";
import { Avatar, Button, Center, Divider, Flex, HStack, Link, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text, VStack } from "@chakra-ui/react";
import { CiAirportSign1, CiHome, CiSettings } from "react-icons/ci";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { AuthManager } from "../../lib/utils/AuthManager";
import { UserProfile } from "../../lib/utils/backend";
import { TbHelpSquareRounded, TbHistory, TbSettings } from "react-icons/tb";
import { LiaNetworkWiredSolid } from "react-icons/lia";

interface Props{
  isExpanded : boolean;
}
// login status item

export const UserStatus = ({isExpanded} : Props) => {
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  
  useEffect(() => {
    setLogin(AuthManager.getInstance().isLoggedIn());
    setProfile(AuthManager.getInstance().getUserProfile());

    console.log('isLogin:', AuthManager.getInstance().isLoggedIn());
    console.log('profile:', AuthManager.getInstance().getUserProfile());

  },[]);


  const handleLogout = () => {
    AuthManager.getInstance().logout();    
    // 추가로 삭제할 항목이 있다면 여기에 작성
    
    // 로그아웃 후 이동할 페이지로 리디렉션
    navigate('/');
  };
  
  
  return (
    <Box>
      {
        (!isExpanded && profile) 
        ?

        <Avatar w={'40px'} h='40px' src={profile.profile_image}  />
        :
        <>
        { isLogin && (
        <HStack>
        { profile && (  
          <Avatar w={'40px'} h='40px' src={profile.profile_image} 
          name={profile.user.user_email}
          />
        )}
        <Spacer />
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            { profile && (  
              
            <Text fontSize={'small'} variant={'link'}>
              {profile.user.user_email}
            </Text>
            )}
            {!profile && ( 
            <Text fontSize={'small'} variant={'link'}>
              Unknown
            </Text>)}
          </MenuButton>
          <MenuList p={3} mt={1}>
            <MenuItem onClick={handleLogout} as={RouterLink} to='/'>
                Sign out
            </MenuItem>
          </MenuList>
        </Menu>
        </HStack>
      )} 
        </>
      }

    </Box>
  )
}

// item icon, name, link
export interface MenuItemProps {
  icon: any;
  name: string;
  link: string;
}

export const NavMenuItem = (prop:MenuItemProps) => {
  const navigate = useNavigate();

  return (
    <Center>
    <Box w='100%' h='40px'  my='3px' alignContent={'center'} p='10px'
    _hover={{bg: 'gray.300', cursor: 'pointer'}}
    style={{borderRadius: '10px'}}
    onClick={() => {
      navigate(prop.link);
    }}
    >
        <HStack>
          {prop.icon}
          <Text>{prop.name}</Text>
        </HStack>
    </Box>
    </Center>
  )
}

export const NavMenuItem2 = (prop:MenuItemProps) => {
  const navigate = useNavigate();

  return (
    <Center>
    <Box w='100%' h='40px' bg='white' my='3px' alignContent={'center'} p='10px'
    _hover={{bg: 'gray.300', cursor: 'pointer'}}
    style={{borderRadius: '10px'}}
    onClick={() => {
      navigate(prop.link);
    }}
    >
        <HStack>
          {prop.icon}
          <Text>{prop.name}</Text>
        </HStack>
    </Box>
    </Center>
  )
}

export const Dashboard = observer(({}) => {
  const navigate = useNavigate();
  try {
    const prjController = projectController;
    if (!prjController.isInitialized()) {
      navigate("/login");
    }

    useEffect(() => {
      const hasChildren = document.querySelector('.outlet-wrapper > *');
      if (!hasChildren) {
        navigate('home');
      }
    }, [navigate]);


    return  (
      <>
        <SidebarWithHeader
          // menu={
          //   <Flex flexDirection={'column'} h='95%'>
          //     <Box>
          //       <NavMenuItem icon={<CiHome size={20} />} name='Home' link='/dashboard/home' />
          //       <NavMenuItem icon={<LiaNetworkWiredSolid size={20} />} name='Workflows' link='/dashboard/projects'/>
          //       <NavMenuItem icon={<TbHistory size={20}/>} name='Activity' link='/dashboard/activity'/>

          //       <Divider mt={'10px'} mb={'10px'}/>

          //       <ProjectList maxItem={5} />
          //     </Box>

          //     <Spacer />

          //     <Box>
          //       <Divider mt={'10px'} mb={'10px'}/>
          //       <NavMenuItem2 icon={<TbHelpSquareRounded size={20}/>} name='Help Center' link='/dashboard/help'/>
          //       <NavMenuItem2 icon={<TbSettings size={20} />} name='Settings' link='/dashboard/settings' />
          //       <Divider mt={'10px'} mb={'10px'}/>
           
          //       <Divider mt={'10px'} mb={'10px'}/>
          //       <Center><Text fontSize={'xs'}>VERONICA 2024.06 </Text></Center>
          //     </Box>
          //   </Flex>
          // }
          >
          <Box className='outlet-wrapper'>
            <Outlet />
          </Box>

        </SidebarWithHeader>
      </>
    )
  }
  catch (e) {
    console.error(e);
    navigate('/login');    
    return null;
  }
});


