import { Box, Tooltip, Center, Avatar, keyframes } from "@chakra-ui/react";
import { Block, deleteBlock } from "../../../../lib/utils/backend";
import React, { useEffect } from "react";

import { Draggable } from 'react-beautiful-dnd';

import { TiDelete } from "react-icons/ti";


const animloader = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const spin = keyframes`
  0%, 100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
`;

const shadowRolling = keyframes`
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }
  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }
  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
`;

interface BlockItemProps {
  block: Block;
  index: number;
  is_drag_disable: boolean;
  isRunning: boolean;
  onDeleted: () => void;
}

export const BlockItem: React.FC<BlockItemProps> = ({ block, index, onDeleted, is_drag_disable, isRunning }) => {
  const [showDelete, setShowDelete] = React.useState(false);

  const onDeleteBlock = (blockId:string) => { 
    console.log('Delete block:', blockId);
    deleteBlock(blockId).then(() => {
      onDeleted();
    });
  }


  let blockComponent = (
    <Tooltip label={block.config.name + '('+ block.config.desc +')'}>
        <Box
        position={'relative'}
        w="60px" h="60px" m="10px"
        background="white" p={'3px'} 
        border={'1px'} borderRadius={'10px'}
        borderStyle={'solid'}
        borderColor={'gray.300'}
        boxShadow={'md'}
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
        //animation={`${shadowRolling} 2s linear infinite`}

      
      _after={isRunning? {
        content: '""',
        boxSizing: "border-box",
        borderRadius: "10px",
        width: "60px",
        height: "60px",
        border: "30px solid #FFFFFF",
        position: "absolute",
        left: 0,
        top: 0,
        animation: `${animloader} 0.4s ease-in-out infinite`,
        animationDelay: "0s",
      }:{}}
      
     /*
      _before={{
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "inherit",
        height: "inherit",
        borderRadius: "50%",
        transform: "rotateX(70deg)",
        animation: `${spin} 1s linear infinite`,
      }}
      _after={{
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "inherit",
        height: "inherit",
        borderRadius: "50%",
        color: "#FF3D00",
        transform: "rotateY(70deg)",
        animation: `${spin} 1s linear infinite`,
        animationDelay: ".4s",
      }}
      */
      >
        
        <Center h='100%'>
          <Avatar src={block.config.icon} borderRadius={'5px'} name={block.config.name}/>
          
        </Center>
        {!is_drag_disable && (
        <Box 
          position="relative"
          top="0"
          right="0"
          transform="translate(80%, -230%)"
          transition="opacity 0.2s"
          _hover={{ opacity: 1 }}
          hidden={!showDelete}
          onClick={() => { onDeleteBlock(block.id) }}
          style={{ cursor: 'pointer' }}
        >
          <TiDelete size={'30px'} color="red.500"/>
        </Box>
        )}
      </Box>
      
      </Tooltip>
      )
  
  const is_termination = block.config.is_termination;

  return (
    !is_termination?
    (
      <Draggable draggableId={block.id} index={index} isDragDisabled={is_drag_disable} >
        {(provided) => (
          <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
            {...provided.dragHandleProps}
            >
          {blockComponent}
          </Box>
        )}
      </Draggable>
    ):
    (
      blockComponent
    )
  )
};