import React, { useEffect, useRef, useState } from "react";
import { Box, Text, HStack, VStack, Icon, Button } from "@chakra-ui/react";
import { FaDotCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Repository, getRepositories } from '$lib/utils/backend';
import { AuthManager } from "$lib/utils/AuthManager";
import { projectController } from "../../store/ProjectController";
import { repositoryController } from "../../store/RepositoryController";
// ... (other imports remain the same)

interface RepositoryListProp {
  onRepositorySelect?: (repo: Repository) => void,
  maxItem: number,
  selectedDate: string,
}


// 유저의 베로니카 작업 기록 내역
const ActivityItem = ((prop:RepositoryListProp)=>{
  const [visibleItems, setVisibleItems] = useState(prop.maxItem);  
  const [loaded, setLoaded] = useState(false);  
  // const [error, setError] = useState(false);
  const navigate = useNavigate();

  const prjController = projectController;
  const repoController = repositoryController;

  useEffect(() => {
    if(prjController.isInitialized()){
      setLoaded(true);
    }
    else{
      navigate('/login');
    }
  }, [setLoaded, prjController, navigate]);

  
  // 유저가 베로니카 작업한 프로젝트 내역 가져오기
  const projects = prjController.getProjects()
  
  // 프로젝트를 last_update 기준으로 정렬 (최신순)
  const sortedProjects = [...projects].sort((a, b) => 
    new Date(b.last_update).getTime() - new Date(a.last_update).getTime()
  );

  // acitivity 내역 하나씩 더 보기
  const showMoreItems = () => {
    setVisibleItems(prev => Math.min(prev + 1, sortedProjects.length));
  };
  // acitivity 내역 접기
  const showLessItems = () => {
    setVisibleItems(prop.maxItem);
  };


  // 특정 날짜 activity 위치로 이동
  console.log('ActivityItem의 selectedDate', prop.selectedDate)
  
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(()=>{
    if(prop.selectedDate && itemRef.current){
      itemRef.current.scrollIntoView({behavior:'smooth'});
    }
  }, [prop.selectedDate]);

  // const sectionRef = useRef<HTMLDivElement>(null);
  // useEffect(()=>{
  //   if(prop.selectedDate && sectionRef.current){
  //     sectionRef.current.scrollIntoView({behavior:'smooth'});
  //   }
  // }, [prop.selectedDate])




  
  

  return (
    <Box w='100%'>
      {sortedProjects.slice(0, visibleItems).map((it)=> (
        <Box key={it.repo_url} position="relative" pl={6} gap='0'> 
          <Box
            position="absolute"
            top={0}
            left={0}
            h="100%"
            w="4px"
            gap='0'
            bgGradient="linear(to-b, hotpink, purple, hotpink)"
          />
          <HStack align="start" spacing={5} gap='0'>
            <Icon
              as={FaDotCircle}
              color="purple"
              boxSize={4}
              position="absolute"
              left="-12px"
              w='28px'
              zIndex={1}
            />
            <VStack align="start" spacing={0} pb='50px' >
              {/* 이동할 곳 */}
              {/* <div key={it.id}  ref={it.last_update.split('T')[0] === prop.selectedDate ? itemRef : null}>
                {it.last_update}
              </div> */}
              <Text fontWeight="bold" ml='10px'>
                {it.last_update.split('T')[0]}  
              </Text>
              <Text 
                fontSize="sm" ml='10px' 
                cursor='pointer'
                onClick={() => window.open(it.repo_url)}
              >
                {it.repo_url}
              </Text>
            </VStack>
          </HStack>
        </Box>
      ))}
      <Box cursor={'pointer'} alignItems={'center'} w='100%'>
        {visibleItems < sortedProjects.length ? (
          <Button w='100%' onClick={showMoreItems}>
            Show more activity
          </Button>
        ) : visibleItems > prop.maxItem ? (
          <Button w='100%' onClick={showLessItems}>
            Show less activity
          </Button>
        ) : null}
      </Box>
    </Box>
  );
});

export default ActivityItem;