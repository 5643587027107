import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Icon,
  Text,
  Flex,
  Divider,
  Center,
  Spacer,
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { Outlet, useNavigate, Link as RouterLink } from "react-router-dom";
import { FiHome, FiTrendingUp, FiCompass, FiStar, FiSettings } from 'react-icons/fi';
import { CiHome } from 'react-icons/ci';
import { LiaNetworkWiredSolid } from 'react-icons/lia';
import { ProjectList } from '../pages/menu/ProjectList';
import { RiGitRepositoryFill, RiGitRepositoryLine } from "react-icons/ri";
import { TbHelpSquareRounded, TbMenu2, TbSettings } from 'react-icons/tb';
import { UserProfile } from '$lib/utils/backend';
import {AuthManager} from '../lib/utils/AuthManager';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { UserStatus } from '../pages/dashboard/dashboard';







const SidebarContent = ({ isExpanded }:any) => {
  const navigate = useNavigate();
  // const items = [ 
  //   { icon: FiHome, label: 'Home' },
  //   { icon: FiTrendingUp, label: 'Trending' },
  //   { icon: FiCompass, label: 'Explore' },
  //   { icon: FiStar, label: 'Favorites' },
  //   { icon: FiSettings, label: 'Settings' },
  // ];
  const topItems = [
    { icon: CiHome, link: 'home' , label:'Home' },
    { icon: LiaNetworkWiredSolid, link: 'projects', label:'Workflows' },
    { icon: FiCompass, link: 'activity' , label:'Activitys' },
  ];

  const bottomItems = [
    { icon: TbHelpSquareRounded, link: 'help', label:'Help' },
    { icon: TbSettings, link: 'settings', label:'Settings' }
  ]



  // const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  
  useEffect(() => {
    setLogin(AuthManager.getInstance().isLoggedIn());
    setProfile(AuthManager.getInstance().getUserProfile());

    console.log('isLogin:', AuthManager.getInstance().isLoggedIn());
    console.log('profile:', AuthManager.getInstance().getUserProfile());

  },[]);


  const handleLogout = () => {
    AuthManager.getInstance().logout();    
    // 추가로 삭제할 항목이 있다면 여기에 작성
    
    // 로그아웃 후 이동할 페이지로 리디렉션
    navigate('/');
  };



  


  return (
    <VStack align="stretch" spacing={5}   h='100%' justifyContent={'space'} zIndex={'3000'}>
      {topItems.map((item, index) => (
        <Flex
          zIndex='3000'
          key={index}
          align="center"
          px={3}
          py={2}
          cursor="pointer"
          _hover={{ bg: 'gray.100' }}
          borderRadius="md"
          onClick={() => { navigate(`/dashboard/${item.link}`);
        }} 
        >
          <Icon as={item.icon} boxSize={6} />
          {isExpanded && (
            <Text ml={4} display="block"  >
              {item.label}
            </Text>
          )}
        </Flex>
      ))}
      <Divider mt={'10px'} mb={'10px'}/>
      {isExpanded 
      ?(
        <ProjectList maxItem={5} />
      ):(
        <Flex
          align="center"
          px={3}
          py={2}
          cursor="pointer"
          _hover={{ bg: 'gray.100' }}
          borderRadius="md"
        >
          <Icon as={RiGitRepositoryLine} boxSize={6} />
        </Flex>
      )
    }
    <Spacer />
    {
      bottomItems.map((item, index)=>(
        <Flex
        zIndex='3000'
        key={index}
        align="center"
        px={3}
        py={2}
        cursor="pointer"
        _hover={{ bg: 'gray.100' }}
        borderRadius="md"
        onClick={() => { navigate(`/dashboard/${item.link}`);
          }} 
        >
        <Icon as={item.icon} boxSize={6} />
        {isExpanded && (
          <Text ml={4} display="block" 
          
          >
            {item.label}
          </Text>
        )}
      </Flex>
      ))
    }
    {/* User Section */}
    <Divider mt={'10px'} mb={'10px'}/>

    <Box>
      {
        (!isExpanded && profile) 
        ?

        <Avatar w={'40px'} h='40px' src={profile.profile_image}  />
        :
        <>
        { isLogin && (
        <HStack>
        { profile && (  
          <Avatar w={'40px'} h='40px' src={profile.profile_image} 
          name={profile.user.user_email}
          />
        )}
        <Spacer />
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            { profile && (  
              
            <Text fontSize={'small'} variant={'link'}>
              {profile.user.user_email}
            </Text>
            )}
            {!profile && ( 
            <Text fontSize={'small'} variant={'link'}>
              Unknown
            </Text>)}
          </MenuButton>
          <MenuList p={3} mt={1}>
            <MenuItem onClick={handleLogout} as={RouterLink} to='/'>
                Sign out
            </MenuItem>
          </MenuList>
        </Menu>
        </HStack>
      )} 
        </>
      }

    </Box>



    <Divider mt={'10px'} mb={'10px'}/>
    {
      isExpanded &&
      <Center>
        <Text fontSize={'xs'}>VERONICA 2024.06 </Text>
      </Center>
    }
    


    
    </VStack>
  );
};

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      zIndex='5000'
      id='hello'
      position="fixed"
      left={0}
      p={5}
      w={isExpanded ? '300px' : '70px'}
      top={0}
      h="100vh"
      bg="white"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius="md"
      transitionProperty="width"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <SidebarContent isExpanded={isExpanded} />
    </Box>
  );
};

export default Sidebar;




