import React, { useState } from "react";
import {
  Avatar,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Box,
  Image,
  IconButton,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import aibotwoman from '../../../assets/images/aiRobotWoman.jpg'
import { FaPlay, FaPause, FaMinus, FaPlus } from "react-icons/fa";
import aibot from "../../../assets/images/aibot.jpg";
// import { Bot } from "./types";
import BotBlocks from "./BotBlocks";
import { FaP } from "react-icons/fa6";

//Bot 인터페이스
interface Bot {
  id: number;
  name: string;
  src: string;
  blocks: string[];
  selected: boolean;
  run: boolean;
  level:number;
}

interface Props {
  bot : Bot;
  handleSelect : Function;
}


function SelectedBot( { bot, handleSelect} : Props) {

  const [isRunning, setIsRunning] = useState(false);


  function handleRunning(){
    setIsRunning(!isRunning)
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
   
    <Box 
                              w='250px'
                              minW='250px'
                              cursor="pointer"
                              bgColor='white'
                              color='rgba(255,255,255, 0.3)'
                              borderRadius='10px'
                              p='20px'
                              boxShadow="xl"
                              textAlign="center"
                              // h='350px'
                              
                            
                              >
                                
                                <Button  bgGradient="linear(to-l, #7928CA, #FF0080)" 
                                  // opacity='100%'
                                  color='white'  bottom='-5%' zIndex='1000' w='80%' h='fit-conent' p='5px'>
                                    Lv {bot.level}
                                </Button>
             
                                <Box display='flex' flexDir='column' >
                                  <Box onClick={onOpen}>
                                  <Image src={aibotwoman}  mb={4} mx="auto" w='200px' h='180px' objectFit={'cover'} borderRadius='10px' position='relative'/>
                                  <Text color='black' fontSize='xl' mb='10px'>{bot.name}</Text>
                                  <Text fontSize="sm" color="gray.400" mb='15px'>{bot.blocks}</Text>

                                  </Box>
                                

                                  <Button  onClick={(e)=> handleSelect(bot.id) } name='minus' w={'fit-content'} margin='0 auto'>
                                    <FaMinus />
                                  </Button>
                                </Box> 
        </Box>
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{bot.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody> 
            {bot.blocks.map((block)=>(
              <Text fontWeight='bold' mb='1rem'>
                 {block}
              </Text>
            ))}
            <BotBlocks blocks={bot.blocks} isRunning={isRunning}/>
         
          </ModalBody>

          <ModalFooter>
          {isRunning 
              ? 
              <Button  mr={3} onClick={handleRunning} m='0 auto'>
                <FaPause/>
              </Button>
              : 
              <Button colorScheme='pink' mr={3} onClick={handleRunning} m='0 auto'>
              <FaPlay />
              </Button>
              
          }
          </ModalFooter>
        </ModalContent>
    </Modal>

    </>
    
    
  );
}

export default SelectedBot;