
import { Box } from "@chakra-ui/layout";
import { WithSubNavigation } from "./nav";
import HeroSecond from "../elements/heroSecond";
import Footer from "../elements/Footer";

export default function AboutUs() {

  return (
    <Box alignContent={'center'} backgroundColor={'black'} h={'100%'}>
      <WithSubNavigation />
      {/* <HText text={'About us'} /> */}
      <HeroSecond></HeroSecond>
      <Footer/>
      {/* <MainFooter/> */}

    </Box>
  );
}

