
import { Box } from "@chakra-ui/layout";
import { WithSubNavigation } from "./nav";
import { Text, Grid, AccordionItem, Accordion, AccordionButton, InputRightElement, InputGroup, Input} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
// import { BlockConfig, getBlockConfigs } from "$lib/utils/backend";
import { AddIcon, MinusIcon, SearchIcon } from "@chakra-ui/icons";
import blockLists from '../elements/blockLists.json';
import blockCategory from '../elements/blockCategory.json';
import { Badge } from '@chakra-ui/react'
import Footer from "../elements/Footer";
import axios from "axios";
import { AuthManager } from "../lib/utils/AuthManager";
import { config } from "process";
import { getBlockConfigs } from "../lib/utils/backend";


const StyledBox=styled(Box)`
  w:100%;
  borderRadius:10px;
  display:flex;
  flexDir:row;
  padding:10px;
  gap:10px;
  border-radius:10px;
  border: solid #e2e8f0 1px;
`

export default function Blocks() {
  //blocks 
  const [view, setView] = useState(0); // id:0 => view all  category id 
  
  const [searchQuery, setSearchQuery] = useState('');  // 사용자 입력 검색 키워드
  // const [isSearch, setIsSearch] = useState(false);  

  const [blocks, setBlocks] = useState([]);

  // get blockList  
  


  // useEffect(()=>{
  //   const fetchData = async () => {
  //   const token = AuthManager.getInstance().getToken();
  //   console.log(token)
  //   try{
  //       const response = await axios({
  //         method: `get`,
  //         url: `https://veronica-api.test-before-deploy.online/v1/blocks/config`,
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         }
  //        });
        
  //       console.log(response)
  //       console.log(response.data)
  //       setBlocks(response.data)
  //     }catch(error:any){
  //       if (error.response && error.response.status === 401) {
  //         console.log('Authentication failed');
  //         // 로그인 페이지로 리다이렉트 또는 토큰 갱신 로직
  //       } else {
  //         console.log('Error in block page:', error);
  //       }
      
  //     }
      
  //   };
  //   fetchData();
  // }, [])
  // console.log('여기는 블록 리스트', blocks)
  // 인증이 없어서 안됨..! block configs 는 인증 필요 없이 가져오도록 수정 필요
  const Data= getBlockConfigs()  
  console.log('block page', Data)

  const filterData=()=>{
    if (searchQuery === ''){
      return blockLists;
    }else{
      // let result= blockLists.filter((value)=> value.title.toLowerCase().includes(searchQuery.toLowerCase()))  //포함
      let result= blockLists.filter((value)=> value.title.toLowerCase().startsWith(searchQuery.toLowerCase()))  //시작
      console.log(result)
      return result
    }
  }
      


  return (
    <Box display="flex" flexDir={"column"} w="100%" h="100vh" >
      <WithSubNavigation></WithSubNavigation>
      <Box w='100%' h='fit-content'>
        <Box w="60%" m="80px auto 10px auto" h="fit-content">
          {/* Blocks Title */}
          <Box mb='80px'>
            <Text color='white' as='b' fontSize='8xl'
              bgClip="text"
              bgGradient="linear(to-tl, #7928CA, pink.300)"
              alignContent={'end'}
              display='block'
            >
              Premium Blocks 
            </Text>
            <Text fontSize='xl' color='gray' >
              veronica makes you grow faster with premium blocks
            </Text>
          </Box>
          
        </Box>
      </Box>
      
      <Box w="60%" m="10px auto 10px auto" h="fit-content">
        {/* Search Bar */}
        <InputGroup size="md" m="20px 0">
          <Input
            pr="4.5rem"
            value={searchQuery}
            type="text"
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            placeholder="Enter keyword"
          />
          <InputRightElement width="4.5rem">
            <SearchIcon color='gray'></SearchIcon>
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box
        display="flex"
        w="60%"
        h="100%"
        m="0 auto"
        flexDir="row"
      >
        {/* blocks category */}
        <Box w="30%" pr='5%'>
          <Accordion allowToggle>
            {blockCategory.map((it) => (
              <AccordionItem 
              key={it.id}
              >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton type='button' value={it.id} onClick={() => setView(isExpanded ? 0 : it.id)} >
                      <Box as='span' flex='1' textAlign='left' color={isExpanded ? 'pink' : 'inherit'}>
                        <Text fontWeight={isExpanded ? 'bold' : ''} >{it.title}</Text>
                      </Box>
                      {isExpanded ? <MinusIcon fontSize='12px'  ml="5px"  float='right'/> : <AddIcon fontSize='12px' ml="5px"  float='right'/>}
                    </AccordionButton>
                  </h2>
                </>
              )}
            </AccordionItem>
          ))}
          </Accordion>
        </Box>
        {/* blocks list */}
        <Box w="70%">
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            gap="10px"
            p="0 10px 10px 10px"
          >
           {
            filterData() && filterData().map((it, index)=>(
            ((it.id === (view || it.id)) || ((it.premium === true) && (view === 6)))&&
            <StyledBox>
              <Box
                bgColor={it.color}
                h="60px"
                w="60px"
                lineHeight={"60px"}
                textAlign={"center"}
                fontSize={"30px"}
                color="white"
                fontWeight="bold"
                flexShrink={"0"} // flex 가로 크기 고정
                margin='auto 1px'
              >
                {it.init}
              </Box>
              <Box display={"flex"} flexDir="column" justifyContent={'center'}>
                <Box w='100%' display='flex' flexDir='row'>
                  {it.premium === true 
                    &&
                      <Badge colorScheme='green' w='fit-content' mb='5px' mr='5px'>
                        premium
                      </Badge>
                  }
                  {it.tag != "" && 
                  <Badge  w='fit-content' mb='5px' >
                    {it.tag}
                  </Badge>
                  }
                 
                </Box>
                <Box display='flex' flexDir='row'>
                  <Box fontWeight="bold" mb='5px'>{it.title}</Box>
                </Box>
                <Box>{it.contents}</Box>
              </Box>
            </StyledBox>  
            )) }
  
          </Grid>
        </Box>
      </Box>
      <Footer/>
    </Box>

    
  
  )};