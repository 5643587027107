
import { observer } from "mobx-react-lite";
import { Box, Center, Grid, GridItem, HStack, Text, useBreakpointValue , Divider, Button, Image, VStack, Icon, AbsoluteCenter , Stepper, StepIndicator, Step, StepStatus, StepIcon, StepNumber, StepTitle, StepDescription, StepSeparator, useSteps, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel} from "@chakra-ui/react";
import ReactGithubCalendar from 'react-github-calendar';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import GitHubCalendar from "react-github-calendar";
import { ChatIcon } from "@chakra-ui/icons";
import { GrUploadOption } from "react-icons/gr";
import { TbCircleArrowUpFilled } from "react-icons/tb";
import CommitGrid from "./CommitGrid";
import ContributionActivity from "./ContributionActivity";
import { useRef, useState } from "react";
import CommitGridOrgin from "./CommitGridOrigin";




export const DashboardActivity = observer (() => {
  // 활동을 자세히 볼 날짜
  const [selectedDate, setSelectedDate] = useState('')
  
  const activityRef = useRef<HTMLDivElement>(null);
  
  const handleDateSelect = (date:string) =>{
    setSelectedDate(date);
    // ActivityItem으로 스크롤
    if(activityRef.current){
      activityRef.current.scrollIntoView({behavior:'smooth'}); 
    }
  }; 

  return (
    <Box>
      <HStack >
      <Text fontSize='5xl' fontWeight={700} >
      Activity
      </Text>
    </HStack>
      <Box p='20px' bg="white" borderRadius={'10px'} w='100%' h='100%' mt='20px' style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}} >
      {/* <Box height="calc(40vh - 40px)" display="flex" flexDirection="column" minW={'800px'} h='fit-content'> */}
        <Box>
        
          <Box bgColor='white' w='fit-content' borderRadius='10px' p='50px 80px 30px 80px' m='0 auto'>
            {/* <CommitGrid 
            // selectedDate={selectedDate} setSelectedDate={setSelectedDate} 
            setSelectedDate={handleDateSelect}
            
            /> */}
            <CommitGridOrgin/>
            <ContributionActivity selectedDate={selectedDate} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
