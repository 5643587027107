import { Box, Flex, Image, useColorModeValue, Text } from '@chakra-ui/react'; 
import React from 'react';
import { useNavigate } from 'react-router-dom';
import footerLogo from '../assets/images/footer3-removebg-preview.png'
import { FaGithub , FaTwitter, FaFacebook} from "react-icons/fa";

function MainFooter(props:any) {
    const navigate = useNavigate();
    return (
        <Box h='fit-content' pt='100px'  mt='200px' pb='30px'
        bgColor='rgba(0,0,0, 0.4)'
        display='flex'
        flexDir='column'
        position='relative' translateY={'-100%'}  
        >   
            {/* Icon Box */}
            <Box display='flex' flexDir={'row'} alignContent={'center'} gap='20px'  mb='10px' justifyContent={'center'}>
                <FaGithub  color='white'/>
                <FaTwitter  color='white'/>
                <FaFacebook  color='white'/>
            </Box>
            
            <Box display='flex' flexDir='row' m ='0 auto'> 
                <Text color='#a0aec0' fontSize='md' textAlign={'center'} mr='5px'> 
                    ⓒ 2024  
                </Text> 
                <Text color='#a0aec0' fontSize='md' textAlign={'center'} mr='5px' 
                onClick={()=> navigate('/aboutus')}
                > 
                     Veronica
                </Text>
                <Text color='#a0aec0' fontSize='md' textAlign={'center'}> 
                    all rights reserved.
                </Text>  

            </Box>
            
            
        </Box>
    );
}

export default MainFooter;
