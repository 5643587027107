import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// page 이동 시 화면 최상단
function ScrollToTop(props:any) {
    const {pathname} = useLocation();
    useEffect(()=>{
        window.scrollTo(0,0)
    }, [pathname]);
    return (
        null
    );
}

export default ScrollToTop;