import { Repository } from "../../lib/utils/backend";
import { makeAutoObservable } from "mobx";

// ProjectCreateController is a class that stores the data for 
// creating a new project.
class ProjectCreateController {
  constructor(){
    makeAutoObservable(this);
    console.log("ProjectCreationData constructor");
  }
  public repo: Repository|null = null;
  public branch: string = "";

  setRepository(repo: Repository){
    this.repo = repo;
  }

  setBranch(branch: string){
    this.branch = branch;
  }

  reset(){
    this.repo = null;
    this.branch = "";
  }

}

export const projectCreateController = new ProjectCreateController()
