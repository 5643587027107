import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Main from "./pages/main";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login, { Logoff } from "./pages/login";
import { Dashboard } from "./pages/dashboard/dashboard";
import { CreateProject } from "./pages/dashboard/createProject";
import { ProjectCards } from "./pages/dashboard/workflow/projectCards";
import { extendTheme } from "@chakra-ui/react";
import { StepsTheme as Steps } from 'chakra-ui-steps';
import { ProjectDetail } from "./pages/dashboard/project/projectDetail";
import { CreateSeqeunce } from "./pages/dashboard/project/createSequence";
import { DashboardHome } from "./pages/dashboard/home/dashboardHome";
import { DashboardSettings } from "./pages/dashboard/settings/dashboardSettings";
import { DashboardHelp } from "./pages/dashboard/help/help";
import { DashboardActivity } from "./pages/dashboard/activity/dashboardActivity";
import Blocks from "./pages/blocks";
import IntroVeronica from "./pages/introVeronica";
import AboutUs from "./pages/aboutUs";

import ScrollToTop from './elements/ScrollToTop';  // page 이동 시 화면 최상단 
import RouteChangeTracker from "./RouteChangeTrack";


const theme = extendTheme({
  components: {
    Steps,
  },
});

export default theme;

export const App = () => {
  
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
      <RouteChangeTracker/>
      <ScrollToTop/>   
      
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logoff" element={<Logoff />} />
          <Route path="/blocks" element={<Blocks />} />
          <Route path="/veronica" element={<IntroVeronica />} />
          <Route path="/aboutus" element={<AboutUs />} />


          <Route path="/dashboard" element={<Dashboard />} >
            <Route path="/dashboard/home" element={<DashboardHome />} />
            <Route path="/dashboard/help" element={<DashboardHelp />} />
            <Route path="/dashboard/settings" element={<DashboardSettings />} />
            <Route path="/dashboard/activity" element={<DashboardActivity />} />
            <Route path="/dashboard/:projectId/*" element={<ProjectDetail />} />
            <Route path="/dashboard/:projectId/create" element={<CreateSeqeunce />} />
            <Route path="/dashboard/projects" element={<ProjectCards />} />
            <Route path="/dashboard/create" element={<CreateProject />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}