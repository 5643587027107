
import { Box } from "@chakra-ui/layout";
import HText from "../elements/hText";
import { WithSubNavigation } from "./nav";

export default function IntroVeronica() {

  return (
    <Box alignContent={'center'} backgroundColor={'black'} h={'100%'}>
      <WithSubNavigation />
      <HText text={'Who is Veronica?'} />
    </Box>
  );
}

