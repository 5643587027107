
import { Box } from "@chakra-ui/layout";
import Hero from "../elements/hero";
import { WithSubNavigation } from "./nav";
import { useEffect } from "react";
import { AuthManager } from "../lib/utils/AuthManager";
import { useNavigate } from "react-router-dom";
// import HeroSecond from "../elements/heroSecond";

export default function Main() {
  const navigate = useNavigate();
  useEffect(() => { 
    if(AuthManager.getInstance().isLoggedIn()){
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <Box alignContent={'center'} h={'100%'}>
      <WithSubNavigation />
      <Hero  />
    </Box>
  );
}

