import { Heading, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Card, CardBody, CardFooter, CardHeader, Input, Box , Container, keyframes} from "@chakra-ui/react";
import { MouseEventHandler, useRef, useState } from "react";
import { PiGearSixDuotone } from 'react-icons/pi';
import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
  useTransform,
} from "framer-motion";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";



const RotationWrapper = styled(motion.div)`
  transform-style: preserve-3d;
  border-radius:10px;
`;
const gear1 = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform : rotate(-360deg);
  }
`;
const gear2 = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform : rotate(360deg);
  }
`;

export type BentoCardProps = {
    title: string;
    text: string;
    footer: string;
    link: string;
    isWaitlist: boolean;
};

export default function BentoCard(prop: BentoCardProps) {
  const [isNext, setIsNext] = useState(false) // join 후 다음 컴포넌트 렌더링
  const [email, setEmail] = useState('') // email 주소 받기
  const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;  // email 정규식 검사
  // const [isEmail, setIsEmail] = useState(false)  // 이메일 형식 검사 boolean
  // email 입력 관리
  const handleEmail =(e: React.ChangeEvent<any>)=> {
      setEmail(e.target.value)
      console.log(email)
  };
  // email 형식 체크
  const emailCheck=(e:any)=>{
      console.log('testing email')
      return emailRegEx.test(email)
  }
  // eamil 에러 메시지 
  const [msg, setMsg]=useState('Enter your email') 

  // join 버튼 이벤트
  function submitEmail(e: { preventDefault: () => void; }){
        e.preventDefault();
        const isValidEmail = emailCheck(email);
        setEmail(""); 
        
        if(isValidEmail){
            console.log('사용 가능한 이메일입니다.')
            setIsNext(true)
        }else{
            console.log('다시 입력해주세요')
            setMsg('Please rewrite your email address.')
            setIsNext(false)
        }
  }


  const navigate = useNavigate();
  const mouseX = useMotionValue(typeof window !== 'undefined' ? window.innerWidth / 2 : 0);
  const mouseY = useMotionValue(typeof window !== 'undefined' ? window.innerHeight / 2 : 0);
  const ref = useRef<HTMLDivElement>(null);
  const dampen = 50;

  const rotateX = useTransform<number, number>(mouseY, (newMouseY) => {
    if (!ref.current) return 0;
    const rect = ref.current.getBoundingClientRect();
    const newRotateX = newMouseY - rect.top - rect.height / 2;
    return -newRotateX / dampen;
  });

  const rotateY = useTransform(mouseX, (newMouseX) => {
    if (!ref.current) return 0;
    const rect = ref.current.getBoundingClientRect();
    const newRotateY = newMouseX - rect.left - rect.width / 2;
    return newRotateY / dampen;
  });

  const circleX = useTransform(mouseX, (newMouseX) => {
    if (!ref.current) return 0;
    const rect = ref.current.getBoundingClientRect();
    return newMouseX - rect.left;
  });

  const circleY = useTransform(mouseY, (newMouseY) => {
    if (!ref.current) return 0;
    const rect = ref.current.getBoundingClientRect();
    return newMouseY - rect.top;
  });

  const gradientBackground = useMotionTemplate`radial-gradient(circle at ${circleX}px ${circleY}px, #F76DFA 0%, rgba(116,51,117,0.2) 100%)`;

  const mouseMove: MouseEventHandler = (e) => {
    animate(mouseX, e.clientX);
    animate(mouseY, e.clientY);
  };

  const mouseLeave: MouseEventHandler = () => {
    animate(mouseX, window.innerWidth / 2);
    animate(mouseY, window.innerHeight / 2);
  };

  return (
    <RotationWrapper
      ref={ref}
      //style={{ rotateX, rotateY, backgroundImage: gradientBackground }}
      style={{ backgroundImage: gradientBackground}}
      onMouseMove={mouseMove}
      onMouseLeave={mouseLeave}
    >
      <Card backgroundColor={'transparent'} textColor={"gray.300"} h={"300px"} w="100%" position="relative" overflow="hidden">
        
        <CardHeader>
          {isNext===false
          ? <Heading size="md">{prop.title}</Heading>
          : <Heading size="md">Submitted!</Heading>
          }
          
        </CardHeader>
        <CardBody >
          {prop.isWaitlist === true
          ?  <Text>Join the wait list now and get ready to be amazed.</Text>
          :  <Text>{prop.text}</Text>
          }
         {/* {isEmail === false && <Text m='0'> {msg} </Text>} */}
          
          {
            (prop.isWaitlist === true)&&(isNext===false)
            &&
            <Box  >
              <Input
              mt='20px'
              // placeholder={isEmail===false ? "Please rewrite your email" : "Enter email"}
              placeholder={msg} 
              value={email}
              type='email'
              p='10px'
              onChange={handleEmail} 
              variant="filled"
              required
              opacity="100%"
              backgroundColor="rgba(0,0,0, 0.4)"
              color="black"
              borderRadius="5px"
              w="100%"
              _hover={{ backgroundColor: "gray.200" }}
              _focus={{ backgroundColor: "gray.200" }}
              ></Input>
            </Box> 
          }
          {
            isNext===true &&
            <Box>
              {/* transforming gear */}
              <Container position='relative'   pt='20px' >
                <Box 
                  position='absolute' 
                  left='31%'
                  animation={`${gear1} 4s linear infinite`}
                >
                  <PiGearSixDuotone size='70px' color='white'></PiGearSixDuotone>
                </Box>
                <Box 
                  position='absolute' 
                  left='46%' 
                  top='43%' 
                  animation={`${gear2} 4s linear infinite`}
                >
                  <PiGearSixDuotone size='70px' color='white'></PiGearSixDuotone>
                </Box>
              </Container>
            </Box>
          }
        </CardBody>
        <CardFooter>
          {prop.isWaitlist === false 
          ? <Button colorScheme="pink" onClick={()=>{navigate(prop.link)}}>{prop.footer}</Button>
          : <Button colorScheme="pink"  onClick={submitEmail}>Join</Button>
          }
          
        </CardFooter>
      </Card>
      
    </RotationWrapper>
  );
}