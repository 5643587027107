import React, { forwardRef, useState } from "react";
import { VStack, Button, Text, Divider } from "@chakra-ui/react";
import ActivityTimeline from "./ActivityTimeline";
import ActivityItem from "./ActivityItem";


interface CommitData {
    commit_day: string;
    commit_count: number;
  }


interface ContributionActivityProps {
    selectedDate: string;
  }


  // const ContributionActivity: React.FC<ContributionActivityProps> = ({ selectedDate }) => {
    
const ContributionActivity = forwardRef<HTMLDivElement, ContributionActivityProps> (({ selectedDate }, ref) => {
    const [showMore, setShowMore] = useState(false);

  return (
    <VStack align="start" spacing={4} width="100%" > 
      <Text fontSize='xl' as='b' mb='10px'>Contribution activity</Text>
      <Divider />
      <ActivityItem maxItem={1} selectedDate = {selectedDate} />

    
    </VStack>
  );
}
);

export default ContributionActivity;
