import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Heading, Image, Spinner, Text, VStack } from "@chakra-ui/react";
import comp_01 from "../assets/images/github.png";
import bg from "../assets/images/bg.png";
import { AuthManager } from "../lib/utils/AuthManager";
import VeronicaLogo from "./logo";
import { getFrontUrl, VERONICA_GITHUB_AUTH } from "../lib/utils/Vars";
import main_image from "../assets/images/main6.png";

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const authManager = AuthManager.getInstance();


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      login(code);
    }
  }, []);



  const login = async (code: string) => {
    setIsLoggingIn(true);
    try {
      await authManager.login('github', code);
      setIsLoggedIn(true);
      navigate("/dashboard");
    } catch (error) {
      console.error('Login failed:', error);
      // 사용자에게 에러 메시지 표시
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (authManager.isLoggedIn()) {
        console.log("login success!!!");
      }
      navigate("/dashboard");
    }
  }, [isLoggedIn]);

  const oauth_redirect_uri = getFrontUrl() + '/login'
  console.log("리다이렉트할 uri", oauth_redirect_uri)

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center" bg="gray.50">
      <Box borderWidth={1} borderRadius="lg" p={8} boxShadow="xl" bg="white" maxWidth="1200px" width="100%">
        <Flex >
          <VStack spacing={6} alignItems="flex-start" flex={1} p={8}>
            <VeronicaLogo />
            <Heading>
              Connecting Repository<br />
            </Heading>

            <Text>no credit card needed</Text>
            <VStack spacing={4} width="100%">
              <Button
                width="100%"
                colorScheme="gray"
                leftIcon={<Image src={comp_01} alt="GitHub" boxSize={6} />}
                onClick={() => { setIsLoggingIn(true); }}
                as="a"
                href={`https://github.com/login/oauth/authorize?client_id=${VERONICA_GITHUB_AUTH()}&redirect_uri=${encodeURI(oauth_redirect_uri)}&scope=user:email%20repo`}
                isDisabled={isLoggingIn}
              >
                Continue with GitHub
              </Button>
              {/*
              <Button width="100%" colorScheme="gray" leftIcon={<Image src={comp_02} alt="GitLab" boxSize={6} />}>
                Continue with GitLab
              </Button>
              <Button width="100%" colorScheme="gray" leftIcon={<Image src={comp_03} alt="Bitbucket" boxSize={6} />}>
                Continue with Bitbucket
              </Button>
              */}
            </VStack>

          </VStack>
          <VStack spacing={8} alignItems="center" justifyContent={'center'} p={8} backgroundImage={bg} borderRadius="lg">
            <Image src={main_image} w={'500px'} />
          </VStack>
        </Flex>
      </Box>
      {isLoggingIn && (
        <Box position="fixed" top={0} left={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center" bg="rgba(0, 0, 0, 0.5)">
          <Spinner size="xl" color="white" />
        </Box>
      )}
    </Flex>
  );
};


export function Logoff() {
  const authManager = AuthManager.getInstance();
  authManager.logout();
  const navigate = useNavigate();
  navigate("/");

  return <></>
}

export default Login;
