
import { useEffect, useState } from 'react';
import {
  Box, HStack,
  Text, Select
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { sequenceCreateController } from "../../store/SequenceCreateController";
import { describeCronExpression } from "../../../lib/utils/cronUtil";


const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CronExpressionInput = () => {
  const [cronExpression, setCronExpression] = useState('');
  const [description, setDescription] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState('');
  const [error, setError] = useState('');

  const seqController = sequenceCreateController;
  //var cronExpression = seqController.getCron();
  useEffect(() => {
    setCronExpression(seqController.getCron());
  },[]);


  useEffect(() => {
    const parseCronExpression = (expression:string) => {
      const [minute, hour, dayOfMonth, month, dayOfWeek] = expression.split(' ');

      if (
        !/^\d+|\*|\d+-\d+|\d+\/\d+|\d+,\d+(,\d+)*$/.test(minute) ||
        !/^\d+|\*|\d+-\d+|\d+\/\d+|\d+,\d+(,\d+)*$/.test(hour) ||
        !/^\d+|\*|\d+-\d+|\d+\/\d+|\d+,\d+(,\d+)*$/.test(dayOfMonth) ||
        !/^\d+|\*|\d+-\d+|\d+\/\d+|\d+,\d+(,\d+)*$/.test(month) ||
        !/^\d+|\*|\d+-\d+|\d+\/\d+|\d+,\d+(,\d+)*$/.test(dayOfWeek)
      ) {
        setError('Invalid Cron Expression');
        return;
      }

      setDescription(describeCronExpression(expression))
      setSelectedMinute(minute);
      setSelectedHour(hour);
      setSelectedDayOfMonth(dayOfMonth);
      setSelectedMonth(month);
      setSelectedDayOfWeek(dayOfWeek);
      setError('');
    };
    
    parseCronExpression(cronExpression);
  }, [cronExpression]);

  const generateCronExpression = (
    {
      m=selectedMinute, h=selectedHour, d=selectedDayOfMonth, mm=selectedMonth, w=selectedDayOfWeek
    }
  ) => {
    const expression = `${m} ${h} ${d} ${mm} ${w}`;
    console.log(expression);
    seqController.setCron(expression);
    setCronExpression(expression);

  };

  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold" maxW={'600px'}>
      {description}
      </Text>
      
      <Box pt={'10px'}>
        <Text fontSize='xs'>Cron Expression</Text>
        <Text >{cronExpression}
        </Text>
        {error && <Text color="red">{error}</Text>}
      </Box>

      <HStack spacing={4} w='100%' pt={'10px'}>
        <Box w='50%'>
          <Text fontSize='xs'>Minute</Text>
          <Select value={selectedMinute} 
            onChange={(e) => { 
              setSelectedMinute(e.target.value); 
              generateCronExpression({m: e.target.value})}
            }>
            <option value="*">Every Minute</option>
            {[...Array(60)].map((_, index) => (
              <option key={index} value={index}>
                {index}
              </option>
            ))}
          </Select>
        </Box>
        <Box w='50%'>
          <Text fontSize='xs'>Hour</Text>
          <Select value={selectedHour} onChange={(e) =>{ setSelectedHour(e.target.value); generateCronExpression({h: e.target.value})} }>
            <option value="*">Every Hour</option>
            {[...Array(24)].map((_, index) => (
              <option key={index} value={index}>
                {index}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>

      <HStack pt={'20px'} w='100%'>  
      <Box w='33%'>
          <Text fontSize='xs'>Day of Month</Text>
          <Select value={selectedDayOfMonth} onChange={(e) => {setSelectedDayOfMonth(e.target.value);generateCronExpression({d: e.target.value}) }}>
            <option value="*">Every Day</option>
            {[...Array(31)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </Select>
        </Box>
        <Box w='33%'>
          <Text fontSize='xs'>Month</Text>
          <Select value={selectedMonth} onChange={(e) => {setSelectedMonth(e.target.value); generateCronExpression({mm: e.target.value})}}>
            <option value="*">Every Month</option>
            {months.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </Select>
        </Box>
        <Box w='33%'>
          <Text fontSize='xs'>Day of Week</Text>
          <Select value={selectedDayOfWeek} onChange={(e) => {setSelectedDayOfWeek(e.target.value); generateCronExpression({w: e.target.value})}}>
            <option value="*">Every Day</option>
            {daysOfWeek.map((day, index) => (
              <option key={day} value={index}>
                {day}
              </option>
            ))}
          </Select>
        </Box>
      </HStack>
    </Box>
  );
};
