import { ReactNode } from 'react';
import {
  Box,
  CloseButton,
  Flex, VStack, useColorModeValue, Text,
  useDisclosure,
  BoxProps,
  Center,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader
} from '@chakra-ui/react';
import VeronicaLogo from './logo';
import { TbLayoutSidebarLeftCollapse, TbMenu2 } from 'react-icons/tb';
import Sidebar from '../elements/Sidebar';

export default function SidebarWithHeader({
  children,
  // menu,
}: {
  // menu: ReactNode;
  children: ReactNode;
  
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({defaultIsOpen: true});
  return (
      <Box>
          <Sidebar />
          <Box ml={['70px', '70px', '70px', '70px']} p={5}>
            {children}
          </Box>
      </Box>
    // <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}   >
    //   {isOpen && (
    //     <>
    //   <SidebarContent
    //     onClose={onClose}
    //     menu={menu}
    //     //display={{ base: 'none', md: 'block' }}
    //   />
        
    //   <Box ml={60} p="4">
    //     {children}
    //   </Box>
    //   </>
    //   )}
      
    //   {
    //   // when closed
    //   !isOpen && (
    //     <>
        
    //     <Box pos={'fixed'} m='5px' w='50px' 
        
    //     overflow={'hidden'} 
    //     h='35px' bg='white'
    //      style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}}>
          
          // <Center>
          //   <TbMenu2 size='30px' onClick={()=>onOpen()}/>
          // </Center>
    //     </Box>
    //     <Box ml={50} p="4">
    //     {children}
    //   </Box>
    //   </>
    //   )}
      
    // </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  menu: ReactNode;
}


