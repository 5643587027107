import { Box, Tooltip, Center, Link, useDisclosure, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Input } from "@chakra-ui/react";
import { addSeqeunceBlocks } from "../../../../lib/utils/backend";

import { BsNodePlus } from "react-icons/bs";

import { BlockLibraryList } from "./BlockLibraryList";
import { useState } from "react";

interface AddBlockProp{
  sequenceId: string;
  is_terminal: boolean;
  onAdded: () => void;
}
export const AddBlock = (prop: AddBlockProp) => {
  const { isOpen:isOpenLib, onOpen:onLibOpen, onClose:onLibClose } = useDisclosure()
  const [selectedBlockId, setSelectedBlockId] = useState('');
  
  const sequenceId = prop.sequenceId;
  const onAdded = prop.onAdded;
  const is_terminal = prop.is_terminal;

  const onAddBlock = () => {
    console.log('Add block:', selectedBlockId);
    if(selectedBlockId === '') return;

    if(is_terminal){
      addSeqeunceBlocks(sequenceId, selectedBlockId, 999).then((res) => {
        console.log("Add terminal block:" + selectedBlockId)
        console.log(res);
        onAdded();
      });
    } else{
      addSeqeunceBlocks(sequenceId, selectedBlockId, -1).then((res) => {
        console.log(res);
        onAdded();
      });
    }
   };

  return (
    <>
    <Tooltip label="Add Block">
      <Link onClick={onLibOpen}>
        <Box
          w="60px" h="60px" m="10px"
          background="white" p={'3px'} 
          border={'1px'} borderRadius={'10px'}
          borderStyle={'dashed'}
          _hover={{bg:'gray.100'}}
          >
            <Center h='100%'>
                <BsNodePlus size={30} />
            </Center>
        </Box>
      </Link> 
    </Tooltip>

    <Drawer
    isOpen={isOpenLib}
    placement='right'
    onClose={onLibClose}
    >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Add Block</DrawerHeader>

      <DrawerBody>
        <Input placeholder='Search here...' />
        <BlockLibraryList is_terminal={is_terminal}  onAddBlock={(x:string)=>{ console.log(x); setSelectedBlockId(x) }}/>
      </DrawerBody>

      <DrawerFooter>
        <Button variant='outline' mr={3} onClick={onLibClose}>
          Cancel
        </Button>
        <Button colorScheme='blue' onClick={()=>{onAddBlock(); onLibClose()}}>Add</Button>
      </DrawerFooter>
    </DrawerContent>
    </Drawer>
  </>
  );
};
