import { Box, HStack, Heading, Text, VStack} from "@chakra-ui/layout";
import { keyframes } from "@emotion/react";
import { Image } from "@chakra-ui/image";
import { useInView } from "framer-motion";
import { useRef } from "react";
//import main_image from "../assets/images/main4.png";
//import main_image from "../assets/images/main5.png";
//import main_image from "../assets/images/main6.png";
import main_image from "../assets/images/main7.png";
//import main_image from "../assets/images/main8.png";

// demo image
import demo_workflow from "../assets/images/demo1_back.png";
import sequence_run from "../assets/images/sequence_run.gif";

export default function HeroSecond() {
    console.log('Hero')
    const animation = keyframes`
    to {
        background-position: 100%;
        }
        `

    const ref = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const isInView = useInView(ref, { margin: "0px 0px -300px 0px", once: true})
    const isInView2 = useInView(ref2, { margin: "0px 0px -100px 0px", once: false})
    const isInView3 = useInView(ref3, { margin: "0px 0px -500px 0px", once: false})
    const isInView4 = useInView(ref4, { margin: "0px 0px -600px 0px", once: false})

    const iconSize = 5;
    return (

        <Box paddingTop={10} paddingBottom={10}
            
            h={'100%'}
            // animation={`${animation} 15s alternate-reverse infinite`}
            //backgroundSize="200% auto"
            // bgImage={background_image} 
            // bgGradient='linear(to-l, #7928CA, #FF0080)' 
            // bgGradient="linear(to-tl, rgba(121, 40, 202,0.9), rgba(0, 0, 0,0.9), rgba(183,16,192,0.9), rgba(0, 0, 0,0.9), rgba(218, 18, 3), rgba(0, 0, 0,0.9) )"
            // bgGradient="linear(to-bl, rgba(121, 40, 202,0.9), rgba(0, 0, 0,0.9), rgba(183,16,192,0.9), rgba(0, 0, 0,0.9), rgba(218, 18, 3), rgba(0, 0, 0,0.9) )"
            // backgroundBlendMode={'multiply'}
            >
            <Box paddingTop={'30px'} >
                <HStack 
                    textAlign={'center'} 
                    justifyContent={'center'}>
                        <VStack>
                            {/* <Icon as={ChevronDownIcon} color={'white'} w={iconSize} h={iconSize} />
                            <Box background="linear-gradient(transparent, #7C72FF 30%); height: 72%" w={'3px'} h={'300px'} /> */}
                        </VStack>
                       
                        <Heading fontSize='7xl' textColor='gray.100' textAlign={'center'}     margin=' 10px 0 80px 0'>
                            <Text 
                                as='span'
                                bgGradient="linear(to-tl, #7928CA, pink.300)" bgClip="text"
                                backgroundSize="200% auto"
                                textAlign={'center'}
                                animation={`${animation} 3s alternate-reverse infinite`}
                                
                            >
                                AUTOMATE.
                            </Text>
                            <br />
                            Your workflow
                            <Text fontSize={'3xl'} textColor={'gray.300'} fontWeight={10}>
                                The world's leading AI-powered repository management platform.
                            </Text>
                        </Heading>
                        
                        {/* <Image src={main_image} w={'500px'} /> */}
                </HStack>

                <Box h='fit-content' w='100%'  mb='50px' p= '50px 0'
                        position='relative'
                        bgGradient='linear(to-br, black, #FF0080, #7928CA,  black, black, #7928CA,#FF0080, black)'
                        // bgGradient="linear(to-b, rgba(121, 40, 202,0.9), rgba(0, 0, 0,0.9), rgba(183,16,192,0.9), rgba(0, 0, 0,0.9), rgba(218, 18, 3), rgba(0, 0, 0,0.9) )"
                    >      
                        <Image src={demo_workflow} w={'1000px'} m='0 auto'  borderRadius={'10px'}  zIndex={'1'}/>
                        <Image src={sequence_run} w={'40%'} bottom='5%' left='30%' position ='absolute' zIndex={'2'} borderRadius={'10px'}/>
                </Box>  
            
            </Box>

        </Box>
    )};