import { Text } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import styled from "@emotion/styled";

const RotationWrapper = styled(motion.div)`
  transform-style: preserve-3d;
`;

export type hTextProp = {
    text: string;
};

export default function HText(prop: hTextProp) {
  return (
  <Text fontSize='xxx-large' fontWeight={700}
          bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text"
        >
        {prop.text}
  </Text>
  );
}

export function H0Text(prop: hTextProp) {
  return (
  <Text fontSize='xxx-large' fontWeight={700}
        >
        {prop.text}
  </Text>
  );
}

export function H1Text(prop: hTextProp) {
  return (
  <Text fontSize='xx-large' fontWeight={500}
          bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text"
        >
        {prop.text}
  </Text>
  );
}

export function H2Text(prop: hTextProp) {
  return (
  <Text fontSize='x-large' fontWeight={300}
          bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text"
        >
        {prop.text}
  </Text>
  );
}

export function H3Text(prop: hTextProp) {
  return (
  <Text fontSize='large' fontWeight={300}
        textColor={'gray.500'}
        >
        {prop.text}
  </Text>
  );
}