import Markdown, { Components } from "react-markdown";
import HText, {H0Text, H1Text, H2Text, H3Text } from "./hText";
import { HStack } from "@chakra-ui/react";

export class MyTheme implements Components {
  h1 = (props: any) => {
    return (
      <H0Text text={props.children} />
    );
  }
  h2 = (props: any) => {
    return (
      <H1Text text={props.children} />
    );
  }
  h3 = (props: any) => {
    return (
      <H2Text text={props.children} />
    );
  }
  h4 = (props: any) => {
    return (
      <H2Text text={props.children} />
    );
  }
  p = (props: any) => {
    return (
      <H3Text text={props.children}/>
    );
  }
  li = (props: any) => {
    return (
      <HStack ml='-30px'>
        <H3Text text="-"/>
        <H3Text text={props.children}/>
      </HStack>
    );
  }
  heading?: Components['h1'];
}

export const newTheme = new MyTheme();