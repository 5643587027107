import { Box, useColorModeValue, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGithub , FaTwitter, FaFacebook} from "react-icons/fa";

function Footer(props:any) {
    const navigate = useNavigate();
    return (
        <Box h='300px' pt='200px' bgColor='transparent'   position='relative' translateY={'-100%'}>
            <Box 
            display='flex'
            flexDir='column'
            w='100%'
            h='90px'
            bgColor='white'
            borderTop={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            pt='20px'
            > 
            {/* Icon Box */}
            <Box display='flex' flexDir={'row'} alignContent={'center'} 
            gap='20px'  mb='10px' justifyContent={'center'} mt='10px'>
                <FaGithub  color='gray'/>
                <FaTwitter  color='gray'/>
                <FaFacebook  color='gray'/>
            </Box>
            <Box display='flex' flexDir='row' m ='0 auto'> 
                <Text color='#a0aec0' fontSize='md' textAlign={'center'} mr='5px'> 
                    ⓒ 2024  
                </Text> 
                <Text color='#a0aec0' fontSize='md' textAlign={'center'} mr='5px' 
                onClick={()=> navigate('/aboutus')}
                > 
                     Veronica
                </Text>
                <Text color='#a0aec0' fontSize='md' textAlign={'center'}> 
                    all rights reserved.
                </Text>  

            </Box>
            
            </Box>
        </Box>
    );
}

export default Footer;