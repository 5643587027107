
import { observer } from "mobx-react-lite";
import { Box, Center, Divider, Flex, Grid, GridItem, HStack, SimpleGrid, Text, VStack, keyframes, useBreakpointValue } from "@chakra-ui/react";
import HText, {H0Text, H1Text, H2Text, H3Text } from "../../../elements/hText";
import { AuthManager } from "../../../lib/utils/AuthManager";
import { useEffect, useState } from "react";
import Markdown, { Components } from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { newTheme } from "../../../elements/markupTheme";
import { useLocation, useNavigate } from "react-router-dom";

const helpMainContent = `
# Unleash the Power of AI with VERONICA! 🚀
Welcome to VERONICA, the ultimate developer tool platform that harnesses the incredible potential of LLM/SLM-based AI technology. 
Experience a quantum leap in software development productivity and quality with our cutting-edge features. 
Upgrade to VERONICA Pro for even more advanced capabilities and unlimited access to the latest LLMs!
`;

const helpContents:{[key: string]: string} = {
  "Introduction": `
  # Introduction
  VERONICA is a revolutionary platform that empowers developers with LLM/SLM-based AI technology.
  Join the future of software development today and experience the VERONICA advantage!
  `,
  "GettingStarted": `
  # Getting Started
  ## Registration
  To start using VERONICA, simply sign up for a free account using your GitHub credentials.
  Upgrade to VERONICA Pro for even more features and benefits!
  `,
  "Features": `
  # Key Features
  VERONICA offers a wealth of powerful features:
  - Extensive library of pre-optimized prompts (expanded for Pro users)
  - Intelligent code analysis and generation
  - Seamless documentation automation
  - Customizable event-driven workflows
  - Insightful performance analytics

  Upgrade to VERONICA Pro for:
  - Access to the latest and most advanced LLMs
  - Increased token limits and workflow executions
  - Priority support and personalized onboarding
  `,
  "Usage": `
  # Usage
  ## Workflow Inspiration
  Discover the limitless potential of VERONICA through these inspiring workflow ideas:

  * Effortlessly document your codebase
    - Set up a "Code Documentation" workflow
    - Target specific file types (e.g., .js, .py)
    - Choose from a variety of documentation styles
    - Watch as VERONICA automatically generates comprehensive documentation

  * Ensure code quality with AI-powered reviews
    - Configure a "Code Review" workflow
    - Define review criteria and severity levels
    - Let VERONICA identify potential issues and suggest improvements
    - Collaborate with your team through automated pull request comments
  
  The possibilities are endless with VERONICA!
  `,
  "Pricing": `
  # Pricing
  VERONICA offers flexible pricing plans to suit your needs:

  - Free Tier: Perfect for individual developers and small teams
  - Pro Tier: Ideal for larger teams and organizations requiring advanced features and higher usage limits
  
  Please visit our pricing page for more details and to compare plans.
  `,
  "Support": `
  # Customer Support
  Our dedicated support team is here to help you every step of the way. 
  Pro users enjoy priority support and personalized onboarding sessions.
  Contact us through the in-app chat or email for prompt assistance.
  `,
}

export const DashboardHelp = observer (() => {
  const navigate = useNavigate();
  const location = useLocation();
  const columns = useBreakpointValue({ base: 1, "xl": 5 });
  
  let markdownMenu = `
  #### [Table of Contents](/dashboard/help)
  `
  + Object.keys(helpContents).map((key) => {
    return `\n- [${key}](#${key})`
    }).join('\n');

  
  const [helpContent, setHelpContents] = useState(helpMainContent);

  useEffect(() => {
    console.log(location.hash)
    if(location.hash){
      const hash = location.hash.replace('#', '');
      // help 서버로 부터 정보를 얻어와야 함
      // 지금은 로컬에서 가져오도록 함
      setHelpContents(helpContents[hash]);


      if(false && helpContents[hash]){
        fetch(`/help/${hash}.md`)
        .then(response => response.text())
        .then(text => {
          setHelpContents(text);
        })
      }
      
   }
  }, [location]);
  

  return (
      <Box height="calc(40vh - 40px)" display="flex" flexDirection="column" minW={'800px'} h='fit-content'>
        <Box>
          <HStack >
          <Text fontSize='xxx-large' fontWeight={700}>
          Help
          </Text>
          </HStack>
        </Box>

        <Center flex="1" >
          <Box p='20px' bg="white" borderRadius={'10px'} w='100%' h='100%' mt='20px' style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}}>
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={10}>
            <GridItem>
              <Box p='10px'  h='100%'>
                <Markdown
                      components={ChakraUIRenderer(newTheme)}
                      children={markdownMenu}
                      skipHtml
                    />
              </Box>
            </GridItem>

            <GridItem colSpan={4}>
                <Box p='10px'>
                <Markdown
                      components={ChakraUIRenderer(newTheme)}
                      children={helpContent}
                      
                      skipHtml
                    />
                </Box>
            </GridItem>

            </Grid>
          </Box>
        </Center>
        
      </Box>
  );

});
