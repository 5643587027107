import { Box, HStack, Text, Input, VStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { sequenceCreateController } from "../../store/SequenceCreateController";
import { useEffect, useState } from "react";

export const StepTargetSelection = observer((
) => {
  const [include, setInclude] = useState('');
  const [exclude, setExclude] = useState('');
  const seqCreateController = sequenceCreateController;

  useEffect(() => {
    setInclude(seqCreateController.getInclude().join(', '));
    setExclude(seqCreateController.getExclude().join(', '));
  },[]);

  const onChangeInclude = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const values = value.split(',').map(v => v.trim());
    seqCreateController.setInclude(values);
    setInclude(value);
  }

  const onChangeExclude = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const values = value.split(',').map(v => v.trim());
    seqCreateController.setExclude(values);
    setExclude(value);
  }
  
  return (
    <>
      <Box>
        <HStack>
          <VStack align={'left'}>
            <Text fontSize={'xs'}>Include</Text> 
            <Input onChange={onChangeInclude} value={include}/>
          </VStack>
        </HStack>
        <HStack>
          <VStack align={'left'}>
            <Text fontSize={'xs'}>Exclude</Text> 
            <Input onChange={onChangeExclude} value={exclude}/>
          </VStack>
        </HStack>
      </Box>
    </>
  );
});