import React, { useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  VStack,
  HStack,
  Button,
  Card,
  Stack,
  Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow
} from "@chakra-ui/react";
import { generateRandomData } from "../../../elements/contributionGraph";

interface CommitData {
    date: string;
    count: number;
  }
  const end_date = new Date().toISOString().split("T")[0]
  // 2024 commit 데이터 랜덤 생성
  const commitData2024 = generateRandomData('2024-01-01', end_date) 
  // console.log("생성한 2024commit 데이터", commitData2024)
  
  // 2023 commit 데이터 랜덤 생성
  const commitData2023 = generateRandomData('2023-01-01', '2023-12-31') 
  // console.log("생성한 2023commit 데이터", commitData2024)
  
  
  const daysOfWeek = ["Mon", "Wed", "Fri"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  

// 
const getCommitCount = (date: Date, commitData: CommitData[]): number => {
  const commitDay = date.toISOString().split("T")[0]; // "YYYY-MM-DD"
  console.log(commitDay)
  const commitInfo = commitData.find((data) => data.date === commitDay);
  return commitInfo ? commitInfo.count : 0;
};

//commit history 색상 표 : less ~ more
const commitColors=[
    "#ebedf0",  //none
    "#e6c3dd",
    "#c363a8",
    "#ff0080",
    "#7928CA",
]

// commit 개수에 따라 색상 변경 
const getCommitColor = (commitCount: number): string => {
  if (commitCount === 0) return "#ebedf0";
  if (commitCount === 1) return "#e6c3dd";
  if (commitCount >= 2 && commitCount <= 3) return "#ff0080";
  if (commitCount >= 4 && commitCount <= 5) return "#c363a8";
  if (commitCount >= 6) return " #7928CA";
  return "#ebedf0"; // Default color if none of above conditions match
};


// 그리드 날짜 할당
const Grid: React.FC<{ year: number }> = ({ year }) => {
    const commitData = year === 2024 ? commitData2024 : commitData2023;
    const firstDay = new Date(Date.UTC(year, 0, 1));
    const startDay = firstDay.getUTCDay();
    // const commitData = year === 2024 ? commitData2024 : commitData2023;
    // const firstDay = new Date(year, 0, 1);
    // const startDay = firstDay.getDay();
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    const totalDays = isLeapYear ? 366 : 365;
  
    
    const [openPopovers, setOpenPopovers] = useState(Array(53 * 7).fill(false))
    console.log(openPopovers)

    const togglePopover = (index : number) => {
      setOpenPopovers(prevState => {
        const newState = [...prevState];
        newState[index] = !newState[index];
        return newState; 
      })
    }
    const gridItems = Array(7).fill(null).map(() => Array(53).fill(null));
    console.log(gridItems)

    // for (let dayOfYear = 0; dayOfYear < totalDays; dayOfYear++) {
    //   const date = new Date(year, 0, dayOfYear + 1);
    for (let dayOfYear = 0; dayOfYear < totalDays; dayOfYear++) {
      const date = new Date(Date.UTC(year, 0, dayOfYear + 1));
      const week = Math.floor((dayOfYear + startDay) / 7);
      const dayOfWeek = (dayOfYear + startDay) % 7;
  
      const commitCount = getCommitCount(date, commitData);
      const color = getCommitColor(commitCount);

      const index =  dayOfYear 
      console.log(index)
      const commitDay = date.toISOString().split("T")[0].split('-');  //2024-12-28
    
      const month = date.toLocaleString('en-US', { month: 'long' });
      var dateNum = commitDay[2]
  
      if (dateNum[0] === '0' ){
        dateNum = dateNum[1] 
      }
      var content =  `${commitCount} contributions   on ${month} ${dateNum}th `;
      if (commitCount === 0){
        content=`No contributions on ${month} ${dateNum}th `;
      }
      
      console.log(`Commit count: ${commitCount}`);
       
      gridItems[dayOfWeek][week] = (
      <Popover trigger="hover">
        <PopoverTrigger>
      
        <Box
          key={date.toISOString()}
          w='10px'
          h='10px'
          bg={color}
          borderRadius="3px"
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            const month = date.toLocaleString('en-US', { month: 'long' });
            const dateNum = date.getDate();
            console.log(`Clicked on ${month} ${dateNum}, ${year}`);
          }}
        />
        </PopoverTrigger>
        <PopoverArrow />
        <PopoverContent p={2} bgColor='rgba(1,1,1,0.8)' color='white' w='fit-content'>
          {content}
        </PopoverContent>
      </Popover>
      );
    }
  
    return (
      <SimpleGrid columns={53} spacing={1} >
        {gridItems.flat().map((item, index) => (
          <Box key={`item-${index}`} w='10px' h='10px'>
            {item}
          </Box>
        ))}
      </SimpleGrid>
    );
  };



const CommitGridOrgin: React.FC = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  const firstCommitDay = new Date(year, 0, 1);
  const firstDay = new Date(firstCommitDay);
  firstDay.setDate(firstDay.getDate() - firstDay.getDay());

  const monthLabels = [];
  let currentMonth = firstDay.getMonth();
  for (let week = 0; week < 52; week++) {
    const date = new Date(firstDay);
    date.setDate(date.getDate() + week * 7);
    if (date.getMonth() !== currentMonth) {
      currentMonth = date.getMonth();
      monthLabels.push(
        <Text key={currentMonth} fontSize="xs" textAlign="center" width="60px">
          {months[currentMonth]}
        </Text>
      );
    } else {
      monthLabels.push(<Box key={week} width="60px"></Box>);
    }
  }

  // contribution 수 일단 계산
  console.log('here')
  console.log(commitData2023)
  let count2023= 0
  commitData2023.map((it) => {
    count2023 += it.count
  })
  
  console.log(count2023)
  let count2024=0
  commitData2024.map((it) => {
    count2024 += it.count
  })

 
  return (
    <Box mb='80px' w='100%' >
        {/* 해당 년도 contribution 개수 */}
        <HStack w='100%' mb='10px'  justifyContent={'space-between'}>
            <Text>
                {year === 2024 ? count2024 : count2023 } contributions in {year}
            </Text>
            <HStack>
            <Stack spacing={3}>
                <Select size='xs' 
                onChange={(e:any)=>setYear(Number(e.target.value))}
                    >
                    <option value='2024' selected>
                        2024
                    </option>
                    <option value='2023' >
                        2023
                    </option>
                </Select>
            </Stack>
            </HStack>
        </HStack>
        {/* 잔디밭 카드 영역*/}
        <Box
        bgColor="white"
        // w="fit-content"
        w='100%'
        p="20px 30px"
        borderRadius="10px"
        boxShadow={"0 1px 3px #999999"}
        >          
            <VStack align="start" spacing={4} width="100%" >
                <Box width="100%" overflowX="auto">
                    <VStack align="start" spacing={2} >
                        <HStack spacing={2}  w='756px' >
                            <Box width="20px"></Box>
                            
                            <SimpleGrid columns={12} spacing={2}>
                                {/* {monthLabels} */}
                                {months.map((m)=>
                                    <Text fontSize="xs" textAlign="center" width="60px">
                                        {m}
                                    </Text>
                            )}
                            </SimpleGrid>
                        </HStack>
                        <HStack align="center" spacing={2} w='756px'>
                            <VStack spacing={2} align="center">
                                {daysOfWeek.map((day) => (
                                <Text key={day} fontSize="xs">
                                    {day}
                                </Text>
                                ))}
                            </VStack>
                            <Grid year={year} />
                        </HStack>
                        
                    </VStack>
                </Box>
                
            </VStack>
            <HStack mt='10px' justifyContent={'flex-end'} gap='3px'>
                <Text fontSize='xs' color='gray' mr='5px'>
                    less
                </Text>
                { commitColors.map((it)=>
                <Box 
                bgColor={it}
                width="10px"
                height="10px"
                borderRadius="3px"
                />
                )}
                <Text fontSize='xs' color='gray' ml='5px'>
                    more
                </Text>
                
            </HStack>
        </Box>
    </Box>
  );
};

export default  CommitGridOrgin;