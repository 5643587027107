import { Box, Text, VStack, HStack, Center, Avatar } from "@chakra-ui/react";
import { BlockConfig, getBlockConfigs } from "../../../../lib/utils/backend";
import React, { useEffect } from "react";

export const BlockLibrary = ({
  name,
  desc,
  icon,
  isSelected,
  onSelect,
}: {
  name: string;
  desc: string;
  icon: string;
  isSelected: boolean;
  onSelect: () => void;
}) => {
  return (
    <Box
      border={"1px"}
      borderRadius={"2px"}
      borderStyle={"solid"}
      borderColor={isSelected ? "blue.500" : "gray.200"}
      borderWidth={isSelected ? "3px" : "1px"}
      _hover={{ bg: "gray.100" }}
      w="100%"
      onClick={onSelect}
      cursor="pointer"
    >
      <HStack>
        <Box
          w="60px"
          h="60px"
          m="10px"
          background="white"
          border={"1px"}
          borderRadius={"10px"}
          borderStyle={"solid"}
          borderColor={"gray.300"}
          boxShadow={"md"}
        >
          <Center h="100%">
            <Avatar
              borderRadius={"0px"}
              size="lg"
              name={name}
              src={icon}
              border="1px"
              shadow={"lg"}
            />
          </Center>
        </Box>
        <VStack align={"left"}>
          <Text fontWeight={800}>{name}</Text>
          <Text fontSize={"xs"} pl={"2px"} pr={"2px"}>
            {desc}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export const BlockLibraryList = (
  { onAddBlock, is_terminal }: 
  { onAddBlock: (v: string) => void; is_terminal: boolean }
) => {
  const [hasError, setHasError] = React.useState(false);
  const [blockConfigs, setBlockConfigs] = React.useState<BlockConfig[]>([]);
  const [selectedBlock, setSelectedBlock] = React.useState<string | null>(null);

  useEffect(() => {
    getBlockConfigs().then((blockConfigs) => {
      // filter out terminal blocks if not terminal
      if (!is_terminal) {
        blockConfigs = blockConfigs.filter((b) => !b.is_termination);
      }
      else{
        // only terminal blocks
        blockConfigs = blockConfigs.filter((b) => b.is_termination);
      }
      setBlockConfigs(blockConfigs);
      console.log(blockConfigs);
    }).catch((e) => {
      console.error(e);
      setHasError(true);
    });
  }, []);

  const handleSelect = (blockName: string) => {
    console.log("Selected block:", blockName);
    setSelectedBlock(blockName);
    onAddBlock(blockName);
  };


  return (
    <Box mt="3px" w="100%">
      <VStack>
        {!hasError && blockConfigs.map((blockConfig) => (
          <BlockLibrary
            key={blockConfig.name}
            name={blockConfig.name}
            icon={blockConfig.icon?blockConfig.icon:""}
            desc={blockConfig.desc?blockConfig.desc:""}
            isSelected={blockConfig.id === selectedBlock}
            onSelect={() => handleSelect(blockConfig.id)}
          />
        ))}
        {hasError && <Text fontWeight={800} color='red'>Error loading block library, retry after few seconds</Text> }
      </VStack>
    </Box>
  );
};