import { observer } from "mobx-react-lite";
import { Box, Heading, Button, Text, Container, Stack, Center, useColorModeValue, VStack, Flex, Spacer, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Steps, Step } from "chakra-ui-steps";
import { useSteps } from "chakra-ui-steps";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StepTargetSelection } from "./stepTargetSelection";
import { StepSchedule } from "./stepSchedule";
import { sequenceCreateController } from "../../store/SequenceCreateController";
import { describeCronExpression } from "../../../lib/utils/cronUtil";
import { projectController } from "../../store/ProjectController";

export const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <Box
      sx={{
        p: 2,
        rounded: "md",
        boxShadow: "sm",
        bg: useColorModeValue("gray.50", "gray.800"),
      }}
      m='10px'
    >
      <Text fontSize="md" color="red.400" fontWeight={"bold"}>
        {message}
      </Text>
    </Box>
  );
};


export const CreateSeqeunce = observer(() => {
  const navigate = useNavigate();
  const param = useParams()
  const projectId = param.projectId;
  console.log(projectId)

  if(!projectId){
    navigate('/dashboard');
  }

  const seqCreateController = sequenceCreateController;
  const prjController = projectController;
  
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const onSubmit = () => {
    switch (activeStep) {
      case 0:
        if(seqCreateController.getInclude().length === 0){
          setErrorMessage("Include is empty");
          return;
        }
        setErrorMessage("");
        nextStep();
        break;
      case 1:
        if(seqCreateController.getListen() === ""){
          setErrorMessage("Schedule is empty");
          return;
        }
        setErrorMessage("");
        nextStep();
        break;
      case 2:
        if(projectId){
          prjController.createSequence(projectId, seqCreateController.getSequenceCreateData());
          seqCreateController.reset();
          navigate(`/dashboard/${projectId}`);
        }
        break;
    }
  };

  const onReset = () => {
    seqCreateController.reset();
    reset();
  };

  return (
    <>
      <Box as="section" bg="bg.surface">
        <Container py={{ base: '16', md: '12' }}>
          <Stack spacing={{ base: '8', md: '5' }}>
            <Stack spacing={{ base: '4', md: '5' }} align="center">
              <Heading size={{ base: 'sm', md: 'lg' }}>New AI assist pipeline</Heading>
              <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
                Create a new AI assist pipeline by selecting files and schedule
              </Text>
              
            </Stack>
          </Stack>
        </Container>
      </Box>
      
      <Center>
      {
        errorMessage && <ErrorMessage message={errorMessage} />
      }
      </Center>
      <Center>
        <Box width={"100%"} bg="white" p={5}>
            <Steps activeStep={activeStep} variant="circles-alt" p={10} >
              <Step label="Target" description="Select file or folder for pipeline.">
                <Center>
                  <StepTargetSelection />
                </Center>
              </Step>
              <Step label="Schedule" description="Set pipeline trigger time.">
                <Center>
                  <StepSchedule />
                </Center>
              </Step>
              <Step label="Confirm" description="Check everything okay">
                <Center>
                  <VStack>
                  <Table variant="simple" maxW={'600px'}>
                    <Thead>
                      <Tr>
                        <Th>Key</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                          <Td>Include</Td>
                          <Td>{seqCreateController.getInclude().join(", ")}</Td>
                        </Tr>
                        <Tr>
                          <Td>Exclude</Td>
                          <Td>{seqCreateController.getExclude().join(", ")}</Td>
                        </Tr>
                        <Tr>
                          <Td>ScheduleType</Td>
                          <Td>{seqCreateController.getListen()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Configuration</Td>
                          <Td>{
                            seqCreateController.getListen() === "cron"?
                              (describeCronExpression(seqCreateController.getDetail())): ("")
                          }({seqCreateController.getDetail()})</Td>
                        </Tr>
                    </Tbody>
                  </Table>
                  </VStack>
                </Center>
              </Step>
            </Steps>

            <Flex>
              <Button m={3} variant={"ghost"} onClick={onReset}>
                  Reset
              </Button>
              <Spacer />
              <Center>
                <Button m={3} variant="ghost" isDisabled={activeStep === 0} onClick={prevStep}>
                  Prev
                </Button>
                
                <Button onClick={onSubmit} variant={'solid'}>
                  {activeStep === 2 ? "Finish" : "Next"}
                </Button>
              </Center>
            </Flex>
            
        </Box>
       
      </Center>
    </>
  );
});
