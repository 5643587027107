import {
  Box,
  Link,
  Text,
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';


export default function VeronicaLogo({to = '/'}) {
  return (
    <Link as={RouterLink} to={to} _hover={{
      textDecoration: 'none',
    }}>
      <Text fontSize='3xl' fontWeight={600} textTransform='uppercase'
        bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text"
      >
        veronica
      </Text>
    </Link>
  )
}
