
import { Box, Center, HStack, Heading, Text, VStack, Grid, GridItem } from "@chakra-ui/layout";
import { keyframes } from "@emotion/react";
import { Image, Button } from "@chakra-ui/react";
import { ScaleFade } from "@chakra-ui/transition";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { chakra } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import BentoCard from "./card";
//import main_image from "../assets/images/main4.png";
//import main_image from "../assets/images/main5.png";
//import main_image from "../assets/images/main6.png";
import main_image from "../assets/images/main7.png";
//import main_image from "../assets/images/main8.png";

// demo image
import demo_create_issue from "../assets/images/demo1.png";
import demo_workflow from "../assets/images/demo1_back.png";
import sequence_run from "../assets/images/sequence_run.gif";
import pull_requset from "../assets/images/pull-requests.png";

import background_image from "../assets/images/background200.png";
import MainFooter from "../elements/mainFooter";

export default function Hero() {
    console.log('Hero')
    const animation = keyframes`
    to {
        background-position: 100%;
        }
        `
    // const isWaitlist=true

    const ref = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const isInView = useInView(ref, { margin: "0px 0px -300px 0px", once: true})
    const isInView2 = useInView(ref2, { margin: "0px 0px -100px 0px", once: false})
    const isInView3 = useInView(ref3, { margin: "0px 0px -500px 0px", once: false})
    const isInView4 = useInView(ref4, { margin: "0px 0px -600px 0px", once: false})

    // const iconSize = 5;
    return (

        <Box paddingTop={10} 
            h={'100%'}
            bgSize='cover'
            animation={`${animation} 15s alternate-reverse infinite`}
            bgImage={background_image} 
            //bgGradient="linear(to-bl, rgba(121, 40, 202,0.9), rgba(0, 0, 0,0.9), rgba(183,16,192,0.9), rgba(0, 0, 0,0.9),rgba(), rgba(218, 18, 3), rgba(0, 0, 0,0.9) )"
            backgroundBlendMode={'multiply'}
            >
            <Center>
                <Box w='1200px' paddingLeft={3} paddingTop={'30px'}>
                    <HStack alignItems={'flex-start'}>
                        <VStack>
                            {/* <Icon as={ChevronDownIcon} color={'white'} w={iconSize} h={iconSize} />
                            <Box background="linear-gradient(transparent, #7C72FF 30%); height: 72%" w={'3px'} h={'300px'} /> */}
                        </VStack>
                        <Box padding={3} />
                        <Heading fontSize='7xl' textColor='gray.100'>
                            <Text as='span'
                                bgGradient="linear(to-tl, #7928CA, pink.300)" bgClip="text"
                                backgroundSize="200% auto"

                                animation={`${animation} 3s alternate-reverse infinite`}
                            >
                                AUTOMATE.
                            </Text>
                            <br />
                            Your workflow
                            <Text fontSize={'3xl'} textColor={'gray.300'} fontWeight={10}>
                                The world's leading AI-powered repository management platform.
                            </Text>
                        </Heading>
                        
                        <Image src={main_image} w={'500px'} />
                    </HStack>
                    <HStack>
                        <VStack>
                            {/* <Icon as={RepeatClockIcon} color={'white'} w={iconSize} h={iconSize} /> */}
                        </VStack>
                        <Box padding={3} />
                        <Button as={RouterLink} to="/login" colorScheme="pink" size='lg'>Get Started</Button>
                    </HStack>
                    <HStack>
                        <VStack>
                            {/* <Box w={iconSize} h={0} />
                            <Box background="linear-gradient(#7C72FF, #2DA44E 80%, #3FB950);" w={'3px'} h={'200px'} /> */}
                        </VStack>
                        <Box padding={3} />
                        <VStack align={'left'}>
                            {/* <Text fontSize={'2xl'} textColor={'gray.400'} fontWeight={400}>
                                Trusted by the world's leading companies
                            </Text>
                           <HStack>
                                <Image src="https://github.githubassets.com/assets/3m-0151c2fda0ce.svg" h='32px' />
                                <Box padding={3} />
                                <Image src="https://github.githubassets.com/assets/kpmg-c249f20c5173.svg" h='32px' />
                                <Box padding={3} />
                                <Image src="https://github.githubassets.com/assets/mercedes-fcf97d2d6ec4.svg" h='32px' />
                                <Box padding={3} />
                                <Image src="https://github.githubassets.com/assets/sap-96248a56d312.svg" h='32px' />
                                <Box padding={3} />
                            </HStack> */}
                        </VStack>

                    </HStack>
                    <HStack>
                        <VStack>
                            {/* <Icon as={ChatIcon} color={'white'} w={iconSize} h={iconSize} /> */}
                        </VStack>
                        <Box padding={3} />
                        <Text fontSize='3xl' textColor='gray.100'>Productivity</Text>
                    </HStack>
                    <HStack alignItems={'flex-start'} mb='40px'>
                        <VStack>
                           {/* <Box w={iconSize} h={0} />
                            <Box background="linear-gradient(rgb(63, 185, 80), rgb(46, 160, 67), transparent);" w={'3px'} h={'200px'} /> */}
                        </VStack>
                        <Box padding={3} />
                        <Heading fontSize='5xl' textColor='gray.100' >
                            <Text as='span' bgGradient="linear(to-tl, green.300, green.500)" bgClip="text" >
                                Accelerate innovation
                            </Text>
                            <br />
                            Our AI-powered workflow<br />
                            everything you need to build better software
                        </Heading>
                    </HStack>

                    <ScaleFade in={isInView} initialScale={0.5} >
                        <Image src={demo_workflow} w={'1000px'} ref={ref} />
                    </ScaleFade>
                    <ScaleFade in={isInView2} initialScale={0.5}>
                        <Image src={sequence_run} w={'700px'} ref={ref2}
                            marginLeft={'100px'} marginTop={'-80px'} />   
                    </ScaleFade>
                    <ScaleFade in={isInView3} initialScale={0.5}>
                        <Image src={demo_create_issue} w={'700px'} ref={ref3}
                            marginTop={'-300px'} marginLeft={'550px'} />
                    </ScaleFade>

                    <ScaleFade in={isInView4} initialScale={0.5}>
                        <Image src={pull_requset} w={'900px'} ref={ref4}
                            marginTop={'-400px'} marginLeft={'100px'} />
                    </ScaleFade>

                    

                    <HStack alignItems={'flex-start'} marginTop={'100px'} >
                        <VStack>
                            {/* <Box w={iconSize} h={0} />
                            <Box background="linear-gradient(transparent, rgb(63, 185, 80), rgb(63, 185, 80), transparent); transition-delay: 0ms;" w={'3px'} h={'300px'} /> */}
                        </VStack>
                        <Box padding={3} />

                        <VStack align={'left'}>
                        <Text fontSize='2xl' textColor='gray.400'  >
                            <chakra.span fontSize='2xl' textColor='white' fontWeight={600}>Veronica </chakra.span>
                            Elevate your repository management, unleash developer productivity.<br/>
                        </Text>

                        <Text fontSize='s' textColor='gray.200'  >
                            VERNOICA empowers you to effortlessly orchestrate workflows across your repository's assets, harnessing the power of AI. Say goodbye to the hassles of prompt engineering and tedious copy-pasting. <br/>
                            With VERONICA, developers can achieve consistent repository management with ease, streamlining their workflow and boosting productivity. <br/>
                            Embrace the future of repository management and unlock the full potential of your development process with VERONICA.
                        </Text>
                        </VStack>
                    </HStack>
                    {/* Bento Section  */}
                    <Box paddingTop={'10px'} mt='30px' />
                    <Grid templateColumns='repeat(3,1fr)' templateRows='repeat(2,1fr)' gridGap={'10px'} >
                        <GridItem colSpan={2} rowSpan={1}>
                            <BentoCard title="Prebuilt AI Blocks"
                                text="We prepared AI Blocks powered by latest LLM(GPT-4, Claud3-Sonnet, and more.) and fined tuned for specialized tasks."
                                footer="List of Blocks"
                                link="/blocks"
                                isWaitlist={false}/>
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            <BentoCard title="BETA is now available"
                                text="you can now join the waitlist for the beta version of our platform."
                                footer="Waitlist" 
                                link="/waitlist"
                                isWaitlist={true}
                                />
                            
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            <BentoCard title="Calling her veronica"
                                text="in a marvel movie, veronica is the name of the hulkbuster suit. actually it is weapon system for extra-terrestrial threats. we are calling our AI assistant veronica."
                                footer="Read more..." 
                                link="/veronica"
                                isWaitlist={false}
                                />
                        </GridItem>
                        <GridItem colSpan={2} rowSpan={1}>
                            <BentoCard title="About us"
                                isWaitlist={false}
                                text="We are a team of developers who are passionate about AI and its potential to change the world."
                                footer="Read more..."
                                link="/aboutus"
                                />
                        </GridItem>
                    </Grid>

                    
                </Box>
            </Center>
            <MainFooter/>
        </Box>
    );
}
