
export function getBaseUrl() {
  console.log('BASE URL :', process.env.REACT_APP_PROXY)
  return process.env.REACT_APP_PROXY || '';
}

export function getBackendUrl() {
  console.log('Backend URL :', process.env.REACT_APP_BACKEND)
  return process.env.REACT_APP_BACKEND || '';
}

export function getFrontUrl() {
  console.log('Front URL :', process.env.REACT_APP_FRONT)
  return process.env.REACT_APP_FRONT || '';
}

export function VERONICA_GITHUB_AUTH() {
  console.log('VERONICA_GITHUB_AUTH:', process.env.REACT_APP_OAUTH_GITHUB_CLIENT_ID);
  return process.env.REACT_APP_OAUTH_GITHUB_CLIENT_ID || '';
}
