import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue, useDisclosure,
  keyframes, Avatar, Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import VeronicaLogo from './logo';
import { useEffect, useState } from 'react';
import { AuthManager } from '../lib/utils/AuthManager';
import { UserProfile } from '../lib/utils/backend';
import { observer } from 'mobx-react-lite';


export const WithSubNavigation = observer(() => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure()
  const [isLogin, setLogin] = useState(false);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  
  useEffect(() => {
    
    //setLogin(AuthManager.getInstance().isLoggedIn());
    //setProfile(AuthManager.getInstance().getUserProfile());
    //auth.setLogin(AuthManager.getInstance().isLoggedIn());
    //auth.setProfile(AuthManager.getInstance().getUserProfile());
    setLogin(AuthManager.getInstance().isLoggedIn());
    setProfile(AuthManager.getInstance().getUserProfile());

    console.log('isLogin:', AuthManager.getInstance().isLoggedIn());
    console.log('profile:', AuthManager.getInstance().getUserProfile());

  },[]);


  const handleLogout = () => {
    AuthManager.getInstance().logout();    
    // 추가로 삭제할 항목이 있다면 여기에 작성
    
    // 로그아웃 후 이동할 페이지로 리디렉션
    navigate('/');
  };

  
  const animation = keyframes`
    to {
       background-position: 100%;
     }
  `

  return (
    <Box >
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <VeronicaLogo to='/'/>
          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
        
        { isLogin && (
            <>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                { profile && (  
                <Text fontSize={'small'} variant={'link'}>
                  {profile.user.user_email}
                </Text>
                )}
                {!profile && ( 
                <Text fontSize={'small'} variant={'link'}>
                  Unknown
                </Text>)}
              </MenuButton>
              <MenuList p={3} mt={1}>
                <MenuItem onClick={handleLogout} as={RouterLink} to='/'>
                    Sign out
                </MenuItem>
              </MenuList>
            </Menu>
            
            { profile && (  
              <Avatar w={'40px'} h='40px' src={profile.profile_image} 
              name={profile.user.user_email}
              />
            )}
            </>
          )} 

          { !isLogin && (
              <>
                <Button
                  as={RouterLink}
                  display={{ base: 'none', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  backgroundSize= "200% auto"

                  bgGradient={'linear(to-r, pink.100,pink.500)'}
                  to={'/login'}
                  
                  _hover={{
                      bgGradient:'linear(to-r, pink.500,pink.300)',
                  }}
                  animation= {`${animation} 1s alternate-reverse infinite`}
                  >
                  Get Started
                </Button>
              </>
          )}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  )
});

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4} >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'2l'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

interface NavItem {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href?: string
}

const NAV_ITEMS: Array<NavItem> = []

const NAV_ITEMS_: Array<NavItem> = [
  {
    label: 'Intro',
    href: '#',
  },
  {
    label: 'Features',
    href: '#',
  },
  /*
  {
    label: 'Features',
    children: [
      {
        label: 'Job Board',
        subLabel: 'Find your dream design job',
        href: '#',
      },
      {
        label: 'Freelance Projects',
        subLabel: 'An exclusive list for contract work',
        href: '#',
      },
    ],
  },
  */
  {
    label: 'About',
    href: '#',
  },
  {
    label: 'Testimonials',
    href: '#',
  },
]