
import { Box, Center, Grid, GridItem, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";



export const DashboardSettings = observer (() => {
  const columns = useBreakpointValue({ base: 1, "2xl": 5 });


  return (
      <Box height="calc(40vh - 40px)" display="flex" flexDirection="column" minW={'800px'}>
        <Box>
          <HStack >
          <Text fontSize='xxx-large' fontWeight={700}>
          Settings
          </Text>
          </HStack>
        </Box>

        <Center flex="1" >
          <Box p='20px' bg="white" borderRadius={'10px'} w='100%' h='100%' mt='20px' style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}}>
          <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={10}>
            <GridItem>
              <Box p='10px'  h='100%'>
              
              </Box>
            </GridItem>

            <GridItem colSpan={3}>
                <Box p='10px'>
                 
                </Box>
            </GridItem>

            <GridItem>
                <Box bg='gray.50' p='10px' borderRadius={'10px'}>
                  
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </Center>
        
      </Box>
  );

});
