import { observer } from "mobx-react-lite";
import { Text, Box, Heading, Center, Table, Thead, Tbody, Tr, Th, Td, VStack } from "@chakra-ui/react";
import { Repository } from "../../lib/utils/backend";
import { useEffect } from "react";
import { repositoryController } from "../store/RepositoryController";
import { projectCreateController } from "../store/ProjectCreateController";

interface SelectRepositoryProp {
  onRepositorySelect: (repo: Repository) => void,
};
export const StepSelectRepository = observer((
  prop: SelectRepositoryProp
) => {
  
  const projectData = projectCreateController;
  const repoController = repositoryController;
  const onRepositorySelect = prop.onRepositorySelect;

  let repositories: Array<Repository> = [];
  if(!repoController.isInitialized()){
    return (
      <Center>
        <Box>
          <Heading>Loading...</Heading>
        </Box>
      </Center>
    )
  }
  else{
    repositories = repoController.getRepositories();
  }
 
  const handleRowClick = (repo: Repository) => {
    onRepositorySelect(repo);
    projectData.setRepository(repo);
    projectData.setBranch("");
  };

  useEffect(() => {
    if(projectData.repo){
      handleRowClick(projectData.repo);
    }
  }, [projectData]);
  

  return (
    <>
      <Box bg={"white"}>
        <Center>
        <VStack>
          <Heading size={'md'}>Select Repository</Heading>
          
          {projectData.repo && 
            <Text size={'sm'}>({projectData.repo.repo_name})</Text>
          }
        
        </VStack>
        </Center>
        <Box h={5} />
        <Center>
          <Box maxHeight={'50vh'} overflowY="auto">
            <Table size="sm" p={4} variant={'simple'}>
              <Thead>
                <Tr>
                  <Th>Repository Name</Th>
                  <Th>URL</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  repositories.map((repo) => {
                    return (
                      <Tr
                        key={repo.repo_url}
                        onClick={() => handleRowClick(repo)}
                        cursor="pointer"
                        bg={projectData.repo?.repo_url === repo.repo_url ? "blue.100" : ""}
                      >
                        <Td>{repo.repo_name}</Td>
                        <Td>{repo.repo_url}</Td>
                      </Tr>
                    );
                  })
                }
              </Tbody>
            </Table>
          </Box>
        </Center>
      </Box>
      
    </>
  );
});