import React, { useEffect, useState } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { Stat, getStat } from '../lib/utils/backend';


interface ContributionGraphProps {
  projectId: string;
  weeksPerRow?: number;
}

export const ContributionGraph: React.FC<ContributionGraphProps> = ({ projectId, weeksPerRow = 10 }) => {
  const renderSquare = (count: number, date: string) => {
    let backgroundColor = 'gray.100';
    if (count > 0 && count <= 5) {
      backgroundColor = 'pink.100';
    } else if (count > 5 && count <= 10) {
      backgroundColor = 'pink.300';
    } else if (count > 10) {
      backgroundColor = 'pink.500';
    }

    return (
      <Tooltip key={date} label={`${count} commits on ${date}`}>
        <Box
          width="10px"
          height="10px"
          bg={backgroundColor}
          borderWidth="1px"
          borderColor="white"
          display="inline-block"
        />
      </Tooltip>
    );
  };

  const renderEmptySquare = () => (
    <Box
      width="10px"
      height="10px"
      bg="gray.100"
      borderWidth="1px"
      borderColor="white"
      display="inline-block"
    />
  );

  //const startDate = new Date('2024-02-01');
  //const endDate = new Date('2024-06-19');
  //today
  const endDate = new Date();
  // start date = before 3 months 15 day ago
  const startDate = new Date();
  startDate.setMonth(endDate.getMonth() - 4);
  startDate.setDate(endDate.getDate() - 15);
  
  const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)) + 1;

  const squares = Array(totalDays).fill(null);

  const [data, setData] = useState<Stat[]>([]);

  useEffect(() => {
    getStat(projectId).then((stat) => {
      //console.log('stat:', stat);
      setData(stat);
    }).catch((error) => {
      console.error('Failed to get stat:', error);
      setData([]);
    });
  }, [projectId]);

  data.forEach(({ count, date }) => {
    const index = Math.floor((new Date(date).getTime() - startDate.getTime()) / (1000 * 3600 * 24));
    squares[index] = renderSquare(count, date);
  });

  const totalWeeks = Math.ceil(totalDays / 7);
  const rows = Math.ceil(totalWeeks / weeksPerRow);

  return (
    <Box>
      {Array(rows).fill(null).map((_, rowIndex) => (
        <Box key={rowIndex} display="flex" marginBottom="4px">
          {Array(weeksPerRow).fill(null).map((_, weekIndex) => {
            const startWeekIndex = rowIndex * weeksPerRow + weekIndex;
            const endWeekIndex = startWeekIndex + 1;
            const startDayIndex = startWeekIndex * 7;
            const endDayIndex = endWeekIndex * 7;

            return (
              <Box key={weekIndex} display="flex" flexDirection="column">
                {squares.slice(startDayIndex, endDayIndex).map((square, dayIndex) => (
                  square || renderEmptySquare()
                ))}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

// start 부터 end 까지의 임의의 데이터 생성
export const generateRandomData = (start:string, end:string): Stat[] => {
  const data: Stat[] = [];
  const startDate = new Date(start);
  const endDate = new Date(end);

  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    const count = Math.floor(Math.random() * 15); // 0부터 14까지의 임의의 커밋 수 생성
    data.push({ count, date: date.toISOString().slice(0, 10) });
  }

  return data;
};

// 예시 1: 일주일 동안 매일 활동이 있는 경우
const data1: Stat[] = [
    { count: 2, date: '2023-06-01' },
    { count: 5, date: '2023-06-02' },
    { count: 3, date: '2023-06-03' },
    { count: 7, date: '2023-06-04' },
    { count: 1, date: '2023-06-05' },
    { count: 4, date: '2023-06-06' },
    { count: 6, date: '2023-06-07' },
  ];
  
  // 예시 2: 일주일 중 특정 요일에만 활동이 있는 경우
  const data2: Stat[] = [
    { count: 10, date: '2023-06-02' },
    { count: 8, date: '2023-06-04' },
    { count: 12, date: '2023-06-06' },
  ];
  
  // 예시 3: 한 달 동안 불규칙적으로 활동이 있는 경우
  const data3: Stat[] = [
    { count: 3, date: '2023-06-01' },
    { count: 7, date: '2023-06-05' },
    { count: 2, date: '2023-06-08' },
    { count: 5, date: '2023-06-12' },
    { count: 1, date: '2023-06-15' },
    { count: 9, date: '2023-06-20' },
    { count: 4, date: '2023-06-23' },
    { count: 6, date: '2023-06-27' },
    { count: 2, date: '2023-06-30' },
  ];
  
  // 예시 4: 한 주에 활동이 전혀 없는 경우
  const data4: Stat[] = [];
  
  // 예시 5: 장기간(3개월)에 걸쳐 불규칙적으로 활동이 있는 경우
  const data5: Stat[] = [
    { count: 5, date: '2023-06-03' },
    { count: 2, date: '2023-06-07' },
    { count: 8, date: '2023-06-15' },
    { count: 3, date: '2023-06-21' },
    { count: 6, date: '2023-07-02' },
    { count: 1, date: '2023-07-08' },
    { count: 4, date: '2023-07-12' },
    { count: 7, date: '2023-07-19' },
    { count: 2, date: '2023-07-25' },
    { count: 9, date: '2023-08-01' },
    { count: 3, date: '2023-08-07' },
    { count: 5, date: '2023-08-13' },
    { count: 1, date: '2023-08-20' },
  ];

  export const samples = [data1, data2, data3, data4, data5];