
import { observer } from "mobx-react-lite";
import { Box, Card, CardBody, Text, CardHeader, HStack, Heading, SimpleGrid, Link, Center, Tooltip, Stack, Avatar, AvatarBadge } from "@chakra-ui/react";
import { projectController } from "../../store/ProjectController";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { repositoryController } from "../../store/RepositoryController";
import { FiGitBranch, FiGithub } from "react-icons/fi";
import { AuthManager } from "../../../lib/utils/AuthManager";
import { useEffect } from "react";
import { ContributionGraph, generateRandomData } from "../../../elements/contributionGraph";
import { PiStackPlusLight } from "react-icons/pi";

export const ProjectCards = observer (() => {
  const navigate = useNavigate();
  const prjController = projectController;
  const repoController = repositoryController;

  if(!prjController.isInitialized()){
    return (
      <Center>
        <Box>
          <Heading>Loading...</Heading>
        </Box>
      </Center>
    )
  }

  useEffect(() => {
    if(!AuthManager.getInstance().isLoggedIn()){
      navigate('/login');
    }
  }, [navigate]);


  return (
    <Box height="calc(40vh - 40px)" display="flex" flexDirection="column" minW={'800px'} h='fit-content'>
        <Box>
          <HStack >
          <Text fontSize='xxx-large' fontWeight={700}>
          Workflows
          </Text>
          </HStack>
        </Box>
        
        
        <Center flex="1"  >
          <Box p='20px' bg="white" borderRadius={'10px'} w='100%' h='100%' mt='20px' style={{boxShadow: '0 0 3px rgba(0,0,0,0.2)'}} >
          <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(220px, 1fr))'>
      {
        prjController.getProjects().map((prj, index) => {

          return (
            <Link key={index} as={ReactRouterLink} to={'/dashboard/' + prj.id} _hover={{ textDecoration: "none" }} >
              <Card h='150px' _hover={{
                    border: "1px solid #cd3d73",
                    bgGradient:"linear(to-tr, white 80%, #fff6c7, #ffd300, #f1d238, #ffd500)"
                    }}>
              {/*<StyledCard h='200px'>*/}
                <CardHeader h='40%' p={'10px'}>
                  <HStack>
                  <FiGithub size={20}/>
                  <Tooltip label={prj.repo_url} aria-label="repo url">
                    <Heading isTruncated size='md'>{ repoController.getRepositoryName(prj.repo_url) }</Heading>
                  </Tooltip>
                  </HStack>
                  <HStack> 
                  <FiGitBranch size={20}/>
                  <Tooltip label={prj.repo_branch} aria-label="repo branch">
                    <Text isTruncated >{prj.repo_branch}</Text>
                  </Tooltip>
                  </HStack>
                </CardHeader>
                <CardBody h='60%' display="flex" flexDirection="column" p={0}>
                  <Center mt="auto" mb='5px' 
                    //border={'1px'} rounded={'2px'} p={1} borderColor={'gray.200'}
                    >
                    <ContributionGraph projectId={prj.id} weeksPerRow={22}/>
                  </Center>
                </CardBody>
              </Card>
            </Link>
            
          )
        })
      }
      {
        // create new project card
        <Link as={ReactRouterLink} to='/dashboard/create' _hover={{
          
          textDecoration: "none" 
           }} >
        <Card h='150px'
          _first={{
            border: "2px dashed #cd3db5",

            bgGradient:"linear(to-br, white 70%, #F78FAD, #FDEB82)",
          }}
            >
            <CardHeader>
              <Center>
                <Heading size='md'>New Workflow</Heading>
              </Center>
            </CardHeader>
            <CardBody p={0}>
              <Center>
                <PiStackPlusLight size={50}/>
                
              </Center>
            </CardBody>
        </Card> 
        </Link>
      }
      </SimpleGrid>
          </Box>
        </Center>
        
      </Box>

  )
  
});
