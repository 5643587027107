import React, { useEffect } from 'react';
import {
  Alert, Box, Flex, HStack, Icon, Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { Project, Repository } from '../../lib/utils/backend';
import { FiGithub } from 'react-icons/fi';
import { RiGitRepositoryFill, RiGitRepositoryLine } from "react-icons/ri";
import { Link, Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { projectController } from '../store/ProjectController';
import { repositoryController } from '../store/RepositoryController';


interface RepositoryListProp {
  onRepositorySelect?: (repo: Repository) => void,
  maxItem: number,
}

export const ProjectList = observer((prop: RepositoryListProp) => {
  const onRepositorySelect = prop.onRepositorySelect || (() => {});

  const [loaded, setLoaded] = React.useState(false);  
  const [error, setError] = React.useState(false);
  const [showAllItems, setShowAllItems] = React.useState(false);
  const navigate = useNavigate();

  const prjController = projectController;
  const repoController = repositoryController;

  const toggleAllItems = () => {
    setShowAllItems(!showAllItems);
  };

  useEffect(() => {
    if(prjController.isInitialized()){
      setLoaded(true);
    }
    else{
      navigate('/login');
    }
  }, [setLoaded, prjController, navigate]);

  const projects = prjController.getProjects();


  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w='100%'
      overflowY="auto"
      // my='3px' 
      alignContent={'center'} 
      // p='10px'
      >
        <Flex
          zIndex='3000'
          align="center"
          px={3}
          py={2}
          cursor="pointer"
          _hover={{ bg: 'gray.100' }}
          borderRadius="md"
          onClick={()=>{navigate("/dashboard")}} 
        >
          <Icon as={RiGitRepositoryLine} boxSize={6} />
          <Text ml={4} display="block" >
              Projects
            </Text>
          </Flex>
     
      { error && (  
        <Alert status='error'>
          There was an error processing your request
        </Alert>
      )}
      { loaded && projects && 
      (
        <Box pl={1} pr={2} px={3}  py={2}> 
          { 
              projects.slice(0, showAllItems ? projects.length : prop.maxItem).map((project, index) => {
              return (
                <Box key={project.id} h='20px' alignContent={'center'} justifyContent={'center'} border={1}>
                  <HStack>
                  <Link to={project.repo_url} target="_blank">
                  <FiGithub />
                  </Link>
                  
                  <Text onClick={() => {
                    navigate('/dashboard/' + project.id);
                  }} fontSize={'xs'} 
                     isTruncated
                    cursor={'pointer'}>
                    { labeling(project) }
                  </Text>
                    
                  </HStack>
                </Box>
                )

                function labeling(project: Project): React.ReactNode {
                  const name = repoController.getRepositoryName(project.repo_url)
                  return (<>{name + '(' + project.repo_branch + ')'}</>);
                }
            })
        }
        </Box>
      )}

      <Box pt={3}/>
      
      <Box pl={1} pt={3} onClick={toggleAllItems} cursor={'pointer'} alignItems={'center'}>
      { loaded && projects && !showAllItems && projects.length > prop.maxItem && (
          <Text   
            color={'gray.500'}
            fontSize="xs" as='sup' >
            Show More...
          </Text>
      )}
      { loaded && projects && showAllItems && projects.length > prop.maxItem && (
          <Text onClick={toggleAllItems} 
            color={'gray.500'}
            fontSize="xs" as='sup'>
            ...Show Less
          </Text>
      )}
      </Box>

    </Box>
  );
});