import React from "react";
import { Box, Avatar } from "@chakra-ui/react";
import { keyframes } from "@chakra-ui/react";

interface BotBlocksProps {
  blocks: string[];
  isRunning: boolean;
}

const laserAnimation = keyframes`
  0% {
    opacity: 0;
    width: 0%;
    left: 0;
  }
  50% {
    opacity: 1;
    width: 100%;
    left: 0;
  }
  100% {
    opacity: 0;
    width: 0%;
    left: 100%;
  }
`;

function BotBlocks({ blocks, isRunning }: BotBlocksProps) {
  return (
    <Box
      position="relative"
      w="100%"
      h="40px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="20px"
      overflow="hidden"
      bg="gray.100"
    >
      {isRunning && (
        <Box
          position="absolute"
          top="0"
          left="-5"
          right="0"
          bottom="0"
          bgGradient="linear(to-r, pink.100, pink.200, pink.400, purple.200)"
          opacity="0.7"
          animation={`${laserAnimation} 3s linear infinite`}
        />
      )}
      {blocks.map((block, index) => (
        <Avatar
          key={index}
          name={block}
          borderRadius="10px"
          size="sm"
          zIndex="1"
          bg={isRunning ? "pink.500" : "gray.500"}
          color="white"
        />
      ))}
    </Box>
  );
}

export default BotBlocks;