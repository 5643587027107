import { UserProfile, getProjects, Project, createProject, deleteProject, createProjectSequence, SequenceCreateData } from "../../lib/utils/backend";
import { makeAutoObservable } from "mobx";
import { AuthManager } from "../../lib/utils/AuthManager";


class ProjectController {
  private profile_: UserProfile | null = null;
  private projects: Array<Project> = [];
  private isLoaded: boolean = false;

  constructor() {
    console.log('ProjectController constructor');
    makeAutoObservable(this);
    //this.initialize();
    AuthManager.getInstance().addLoginListener(this.handleLoginStateChange);
  }

  private async initialize() {
    if (AuthManager.getInstance().isLoggedIn()) {
      console.log('fetch projects started...');
      await this.fetchProjects();
      const profile = await AuthManager.getInstance().getUserProfile();
      this.profile_ = profile;
      this.isLoaded = true;
      console.log('fetch projects completed...');
    } else {
      console.log('not login: fetch repositories stopped...');
      this.isLoaded = false;
      this.projects = [];
    }
  }

  private handleLoginStateChange = () => {
    this.initialize();
  };

  public isInitialized() {
    return this.isLoaded;
  }

  public fetchProjects() {
    // fetch projects
    getProjects().then((projects) => {
      console.log('projects:', projects);
      this.setProjects(projects);
    });
  }

  private setProjects(projects: Array<Project>) {
    this.projects = projects;
  }

  public getProjects() {
    return this.projects;
  }


  public getProjectFromId(id: string) {
    return this.projects.find((project) => project.id === id);
  }

  public createProject(repoUrl: string, repoBranch: string) {
    console.log('create project:', repoUrl, repoBranch);
    createProject(repoUrl, repoBranch).then((project) => {
      console.log('created project:', project);
      this.fetchProjects();
    });
  }

  public deleteProject(projectId: string) {
    console.log('delete project:', projectId);
    deleteProject(projectId).then(() => {
      this.fetchProjects();
    });
  }


  public createSequence(projectId: string, data: SequenceCreateData){
    console.log('create sequence:', projectId, data);
    createProjectSequence(projectId, data).then(() => {
      this.fetchProjects();
    });
  }

  public deleteSequence(projectId: string, data: SequenceCreateData){
    console.log('create sequence:', projectId, data);
    createProjectSequence(projectId, data).then(() => {
      this.fetchProjects();
    });
  }

  
}

export const projectController = new ProjectController()
