import { observer } from "mobx-react-lite";
import { Box, Text, Spinner, Center, Link } from "@chakra-ui/react";
import { projectController } from "../../store/ProjectController";
import { Project, SequenceConfig, getProjectSequences } from "../../../lib/utils/backend";
import { repositoryController } from "../../store/RepositoryController";
import { FaPlus } from "react-icons/fa";
import React, { useEffect } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";


import { SequenceGroup } from "./sequenceGroup/sequenceGroup";
import { describeCronExpression } from "../../../lib/utils/cronUtil";


interface WorkflowEditProp {
  project: Project;
}

export const WorkflowEdit = observer((prop: WorkflowEditProp) => {
  
  const projController = projectController;
  const repoController = repositoryController;
  const [sequences, setSequences] = React.useState<SequenceConfig[]>([]);

  const project = prop.project;

  if(!projController.isInitialized() || !repoController.isInitialized()){
    return <Spinner />
  }

  const sequenceLoad = () => {
    getProjectSequences(project.id).then((res) => {
      console.log(res);
      setSequences(res);
    }).catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    sequenceLoad();
  }, [project]);

  return (
    <Box >
        {
          sequences.map((seq, index) => {
            let name = 
            "Target: " + seq.target.include + " / " +
            "Trigger: " +
                  (seq.schedule.listen === "cron" ? 
                    describeCronExpression(seq.schedule.detail): 
                    seq.schedule.listen + " (" + seq.schedule.detail + ")");
            return (
            <Box rounded={"md"} bg={'white'}  p={4} w={"100%"} 
                boxShadow={'md' }
                mt={5}
                backgroundColor={'white'}
              >
                <SequenceGroup onDeleted={sequenceLoad} key={seq.id} sequenceId={seq.id} name={name} />
            </Box>
            );
          })
        }
      <Box pt={5}/>
      <Box rounded={"md"}  p={4} w={"100%"}
        boxShadow={'md' } bg={'white'}
      >
        <NewSequence/>
      </Box>
    </Box>
  );
});

export const NewSequence = () => {
  const param = useParams();
  const projectId = param.projectId;
  return (
    <Link _hover={{textDecoration:'none'}} as={ReactRouterLink} to={'/dashboard/' + projectId + '/create'} >
    <Box  h={"100%"} backgroundColor={'white'}
        border={'1px'} rounded={'5px'} p={5} borderColor={'gray.500'}
        borderStyle={'dashed'}
        _hover={{bg:'gray.100'}}
        >
      <Center h={"100%"}>
        
        <Box>
          <Center h='100%'>
            <FaPlus size={20} />
            <Text fontSize={'large'} fontWeight={'800'}>New workflow</Text>
          </Center>
        </Box>
        
      </Center>
    </Box>
    </Link>
  );
}