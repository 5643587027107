import { observer } from "mobx-react-lite";

import { useEffect, useState } from 'react';
import {
  Box, HStack,
  Text,
  RadioGroup, Radio,
  useRadioGroup,
  Center
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { sequenceCreateController } from "../../store/SequenceCreateController";
import { GoRepoPush, GoIssueOpened, GoGitPullRequest } from "react-icons/go";
import { EventTypeCard, RadioCard } from "../../../elements/radioCard";


export const EventSelection = observer(() => {
  const seqController = sequenceCreateController;
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    setEventType(seqController.getEvent());
  }, []);

  const handleEventChange = (value:string) => {
    seqController.setDetail(value)
    setEventType(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'eventType',
    defaultValue: seqController.getDetail(),
    onChange: handleEventChange,
  });

  const group = getRootProps();

  return (
    <Box>
      <Center>
      <HStack {...group} pb='10px'>
          <EventTypeCard  {...getRadioProps({ value: 'push'})} >
            <HStack>
              <GoRepoPush size={'20px'}/>
              <Text>Push</Text>
            </HStack>
          </EventTypeCard>
          <EventTypeCard {...getRadioProps({ value: 'pull_request' })}>
            <HStack>
              <GoGitPullRequest size={'20px'}/>
              <Text>Pull Request</Text>
            </HStack>
          </EventTypeCard>
          <EventTypeCard {...getRadioProps({ value: 'issue_opened' })}>
            <HStack>
              <GoIssueOpened size={'20px'}/>
              <Text>Issue Opened</Text>
            </HStack>
          </EventTypeCard>
      </HStack>
      </Center>
    </Box>
  );
});

