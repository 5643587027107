import { Repository, getBranches, getRepositories } from "../../lib/utils/backend";
import { makeAutoObservable } from "mobx";
import { AuthManager } from "../../lib/utils/AuthManager";


class RepositoryController {
  private repositories: Array<Repository> = [];
  private isLoaded: boolean = false;
  constructor() {
    console.log('Repository constructor');
    makeAutoObservable(this);
    AuthManager.getInstance().addLoginListener(this.handleLoginStateChange);
    //this.initialize();
  }

  private async initialize() {
    if (AuthManager.getInstance().isLoggedIn()) {
      console.log('fetch repositories started...');
      await this.fetchRepositories();
      this.isLoaded = true;
      console.log('fetch repositories completed...');
    }
    else{
      console.log('not login: fetch repositories stopped...');
      this.setRepositories([]);
      this.isLoaded = false;
    }
  }

  public isInitialized() {
    return this.isLoaded;
  }

  private handleLoginStateChange = () => {
    this.initialize();
  };

  public setRepositories(repositories: Array<Repository>) {
    this.repositories = repositories;
  }

  public getRepositories() {
    return this.repositories;
  }

  public getRepositoryFromUrl(url: string) {
    return this.repositories.find((repo) => repo.repo_url === url);
  }

  public getRepositoryFromName(name: string) {
    return this.repositories.find((repo) => repo.repo_name === name);
  }

  public getBranchesFromRepository(repoUrl: string): Promise<Array<string>> {
    return getBranches(repoUrl).then((branches) => {
      return branches;
    });
  }


  public getRepositoryName(repoUrl: string){
    const repo = this.getRepositoryFromUrl(repoUrl);
    if(repo){
      // return split repo name and validate
      const repoName = repo.repo_name.split('/');
      if(repoName.length > 1){
        return repoName[1];
      }
      else{
        return repo.repo_name;
      }
    }else{
      return repoUrl;
    }
  }


  private fetchRepositories() {
    getRepositories().then((repositories) => {
      this.setRepositories(repositories);
    });
  }
}

export const repositoryController = new RepositoryController()
