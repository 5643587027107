import { SequenceCreateData } from "$lib/utils/backend";
import { makeAutoObservable } from "mobx";

class SequenceCreateController {

  private include: Array<string> = ["*.*"];
  private exclude: Array<string> = [];

  private listen: string="cron";
  private detail: string="1 * * * *";

  constructor(){
    makeAutoObservable(this);
    console.log("SequenceCreateController constructor");
  }

  public getSequenceCreateData(){
    return {
      target:{
        include:this.include,
        exclude:this.exclude
      },
      schedule:{
        listen:this.listen,
        detail: (this.listen === "cron"?this.detail : [this.detail]) 
      }
    } as SequenceCreateData;
  }

  reset(){
    this.include = ["*.*"];
    this.exclude = [];
    this.listen = "cron";
    this.detail = "1 * * * *";
  }

  getInclude(){
    return this.include;
  }

  getExclude(){
    return this.exclude;
  }

  setInclude(include:Array<string>){
    this.include = include;
  }

  setExclude(exclude:Array<string>){
    this.exclude = exclude;
  }

  setListen(listen:string){
    if(listen === "cron"){
      this.listen = listen;
      this.detail = "1 * * * *";
    }
    else{
      this.listen = listen;
      this.detail = "push";
    }
  }

  getListen(){
    return this.listen;
  }

  getDetail(){
    return this.detail;
  }

  setDetail(detail:string){
    this.detail = detail;
  }

  isCron():boolean{
    return this.listen === "cron";
  }

  isEvent():boolean{
    return this.listen === "event";
  }

  getEvent():string{
    if(this.listen === "event"){
      return this.detail;
    }
    else{
      return "push";
    }
  }

  getCron():string{
    if(this.listen === "cron"){
      return this.detail;
    }
    else{
      return '1 * * * *';
    }
  }

  setCron(cron:string){
    this.detail = cron;
  }

}

export const sequenceCreateController = new SequenceCreateController()
