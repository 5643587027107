export function describeCronExpression(expression: string): string {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = expression.split(' ');
  
    const descriptions: string[] = [];
  
    if (minute === '*' && hour === '*') {
      descriptions.push('Every minute');
    } else if (minute === '*') {
      const startTime = formatTime(parseInt(hour, 10), 0);
      const endTime = formatTime(parseInt(hour, 10), 59);
      descriptions.push(`Every minute, between ${startTime} and ${endTime}`);
    } else if (hour === '*') {
      descriptions.push(`At ${minute} minutes past the hour`);
    } else {
      const formattedTime = formatTime(parseInt(hour, 10), parseInt(minute, 10));
      descriptions.push(`At ${formattedTime}`);
    }
  
    if (dayOfMonth !== '*') {
      descriptions.push(`on day ${dayOfMonth} of the month`);
    }
  
    if (month !== '*') {
      const monthName = getMonthName(parseInt(month, 10));
      descriptions.push(`only in ${monthName}`);
    }
  
    if (dayOfWeek !== '*') {
      const dayName = getDayName(parseInt(dayOfWeek, 10));
      descriptions.push(`only on ${dayName}`);
    }
  
    return descriptions.join(', ');
  }
  
  function formatTime(hour: number, minute: number): string {
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute.toString().padStart(2, '0');
    return `${formattedHour.toString().padStart(2, '0')}:${formattedMinute} ${meridiem}`;
  }
  
  function getMonthName(month: number): string {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[month - 1];
  }
  
  function getDayName(day: number): string {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayNames[day];
  }