import { observer } from "mobx-react-lite";

import { useEffect, useState } from 'react';
import {
  Box, HStack, useRadioGroup,
  Center
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { sequenceCreateController } from "../../store/SequenceCreateController";
import { RadioCard } from "../../../elements/radioCard";
import { CronExpressionInput } from "./stepScheduleCron";
import { EventSelection } from "./stepScheduleEvent";


export const StepSchedule = observer(() => {
  const seqController = sequenceCreateController;
  const [scheduleType, setScheduleType] = useState('cron'); // 기본값을 'cron'으로 설정

  useEffect(() => {
    setScheduleType(seqController.getListen())
  }, []);

  const handleScheduleTypeChange = (value: string) => {
    seqController.setListen(value);
    setScheduleType(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'scheduleType',
    defaultValue: seqController.getListen(),
    onChange: handleScheduleTypeChange,
  });

  const group = getRootProps();

  return (
    <>
      <Box w='600px'>
        <Center>
        <HStack {...group} pb='10px'>
            <RadioCard {...getRadioProps({ value: 'cron' })}>
              Scheduled
            </RadioCard>
            <RadioCard {...getRadioProps({ value: 'event' })}>
              Trigger on Event
            </RadioCard>
            <RadioCard {...getRadioProps({ value: 'none' })}>
              On Demand
            </RadioCard>
        </HStack>
        </Center>
        {scheduleType === 'cron' && <CronExpressionInput />}
        {scheduleType === 'event' && <EventSelection />}
        
      </Box>
    </>
  );
});